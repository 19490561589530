import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Form, Field, Empty, Dialog, Loading, Uploader, PullRefresh, RadioGroup, Radio, Overlay, DatetimePicker, Switch, Stepper, Button, Divider, Tag, Tab, Tabs, Card, Popup, Picker, Toast, Cell, CellGroup, Icon, Image as VanImage, Lazyload, Tabbar, TabbarItem, NavBar, PasswordInput, NumberKeyboard, Area, Grid, GridItem, List } from "vant";

Vue.config.productionTip = false;

// webapp 参照 https://blog.csdn.net/qq_37860634/article/details/104666962
// https://vant-contrib.gitee.io/vant/#/zh-CN/form
// https://vux.li/
Vue.use(Form);
Vue.use(Field);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Uploader);
Vue.use(PullRefresh);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Overlay);
Vue.use(DatetimePicker);
Vue.use(Switch);
Vue.use(Stepper);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Tag);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Card);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Area);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(List);

new Vue({
  router,
  store,
  // watch: {
  //   //  this.$route.path
  //   '$route.path': function (newVal, oldVal) {
  //     console.log(newVal + ' --- ' + oldVal)
  //   }
  // },
  render: h => h(App)
}).$mount("#app");
