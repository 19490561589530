import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import {getInfo} from "@/api/login";

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "订货",
      active: "cart",
      isShowTabbar: true
    }
  },
  {
    path: "/phoneInput",
    name: "PhoneInput",
    component: () => import("../views/login/PhoneInput.vue"),
    meta: {
      title: "输入手机号码",
      isShowTabbar: false
    }
  },
  {
    path: "/validCodeInput",
    name: "ValidCodeInput",
    component: () => import("../views/login/ValidCodeInput.vue"),
    meta: {
      title: "输入验证码",
      isShowTabbar: false
    }
  },
  {
    path: "/setRegionManager",
    name: "SetRegionManager",
    component: () => import("../views/login/SetRegionManager.vue"),
    meta: {
      title: "指定区域经理",
      isShowTabbar: false
    }
  },
  {
    path: "/checkPending",
    name: "CheckPending",
    component: () => import("../views/login/CheckPending.vue"),
    meta: {
      title: "区域经理审核中",
      isShowTabbar: false
    }
  },
  {
    path: "/reviewedByMe",
    name: "ReviewedByMe",
    component: () => import("../views/todo/ReviewedByMe.vue"),
    meta: {
      title: "待我审核",
      isShowTabbar: false
    }
  },
  {
    path: "/reviewed",
    name: "Reviewed",
    component: () => import("../views/todo/Reviewed.vue"),
    meta: {
      title: "待审核",
      isShowTabbar: false
    }
  },
  {
    path: "/received",
    name: "Received",
    component: () => import("../views/todo/Received.vue"),
    meta: {
      title: "待收货",
      isShowTabbar: false
    }
  },
  {
    path: "/delivered",
    name: "Delivered",
    component: () => import("../views/todo/Delivered.vue"),
    meta: {
      title: "待送货",
      isShowTabbar: false
    }
  },
    {
        path: "/allOrder",
        name: "AllOrder",
        component: () => import("../views/order/AllOrder.vue"),
        meta: {
            title: "全部订单",
            isShowTabbar: false
        }
    },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: () => import("../views/order/OrderDetail.vue"),
    meta: {
      title: "订单详情",
      isShowTabbar: false
    }
  },
  {
    path: "/storeMap",
    name: "StoreMap",
    component: () => import("../views/todo/StoreMap.vue"),
    meta: {
      title: "门店定位",
      isShowTabbar: false
    }
  },
  {
    path: "/storeMapNav",
    name: "StoreMapNav",
    component: () => import("../views/todo/StoreMapNav.vue"),
    meta: {
      title: "门店导航",
      isShowTabbar: false
    }
  },
  {
    path: "/storeInfo",
    name: "StoreInfo",
    component: () => import("../views/login/StoreInfo.vue"),
    meta: {
      title: "门店信息",
      isShowTabbar: false
    }
  },
  {
    path: "/regionSetting",
    name: "RegionSetting",
    component: () => import("../views/login/RegionSetting.vue"),
    meta: {
      title: "区域设置",
      isShowTabbar: false
    }
  },
  {
    path: "/exchangeGoods",
    name: "ExchangeGoods",
    component: () => import("../views/order/ExchangeGoods.vue"),
    meta: {
      title: "门店换货",
      isShowTabbar: false
    }
  },
  {
    path: "/exchangeGoodsForRegionStock",
    name: "ExchangeGoodsForRegionStock",
    component: () => import("../views/order/ExchangeGoodsForRegionStock.vue"),
    meta: {
      title: "库存换货",
      isShowTabbar: false
    }
  },
  {
    path: "/my",
    name: "My",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/My.vue"),
    meta: {
      title: "我的",
      active: "my",
      isShowTabbar: true
    }
  }
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
});

// 前置路由守卫
router.beforeEach((to, from, next) => {
  // 要去往的路由对象
  // console.log("to", to);
  // 从哪里来的路由
  // console.log("from", from);
  // next放行  可以顺利到达要去的路由

  // 表示登录注册页面
  if (to.path == "/phoneInput" || to.path == "/validCodeInput" || to.path == "/setRegionManager") {
    next();
    return;
  } else {
    // 获取登录凭证
    let token = localStorage.getItem("token");
    // 判断有没有登录
    if (token) {
      // 获取用户信息，并判断登录是否失效
      getInfo().then(r => {
        console.log(r);
        let phone = r.data.phone;
        let status = r.data.status;
        // 临时账号 还未设置
        if(status == -9) {
          // 回去设置吧
          next({ path: '/setRegionManager', query: { phone: phone, status: status }});
          return;
        } else if(status == -1 || status == -2 || status == -7 || status == -8) {
          // 区域经理审核中 或者 城市经理审核中 或者 注册被驳回
          next({ path: '/checkPending', query: { phone: phone, status: status }});
          return;
        }
      });

      // 跳至登录注册页面
    } else {
      // 跳至登录注册页面
      next("/phoneInput"); // 跳转到phoneInput路由
      return;
    }
  }

  // // 表示去往购物车页面
  // if (to.path == "/cart") {
  //   // 判断有没有登录,判断token是否存在
  //   if (token) {
  //     next();
  //   } else {
  //     // 表示没有登录
  //     Vue.prototype.$toast("请先登录");
  //     setTimeout(() => {
  //       next("/user"); // 跳转到user路由
  //     }, 1000);
  //   }
  //   // return
  // }

  next();
});

export default router;
