<template>
  <div class="home">
    <OrderByRegionManager v-if="roleKbn == 0" />
    <OrderByTerminator v-if="roleKbn == 1" />
<!--    <HelloWorld msg="Welcome to Your Vue.js App" />-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import OrderByRegionManager from "@/views/order/OrderByRegionManager.vue";
import OrderByTerminator from "@/views/order/OrderByTerminator.vue";

export default {
  name: "Home",
  components: {
    OrderByTerminator,
    OrderByRegionManager,
    // HelloWorld
  },
  data() {
    return {
      roleKbn: null,
    };
  },
  created() {
    let role = localStorage.getItem("role");
    if(role == 'region_manager') {
      this.roleKbn = 0;
    } else if(role == 'terminator') {
      this.roleKbn = 1;
    }

    this.fetchData()
  },
  methods: {
    fetchData() {
    },
  },
};
</script>
