<template>
  <div>
      <van-tabbar v-model="active" @change="onChange">
          <van-tabbar-item name="cart" to="/" :icon="dhIcon">订货</van-tabbar-item>
          <van-tabbar-item name="my" to="/my" :icon="myIcon">我的</van-tabbar-item>
      </van-tabbar>
  </div>
</template>

<script>
import icon_dh from "@/assets/icon_dh.png";
import icon_dh2 from "@/assets/icon_dh2.png";
import icon_my from "@/assets/icon_my.png";
import icon_my2 from "@/assets/icon_my2.png";

export default {
  data () {
    return {
      icon_dh: icon_dh,
      icon_dh2: icon_dh2,
      icon_my: icon_my,
      icon_my2: icon_my2,
      dhIcon: icon_dh,
      myIcon: icon_my2,

      active: this.$route.meta.active
    }
  },
  //通过这个获取到网页的路径 ps:this.$route(只读)，this.$router(跳转)
  mounted() {
    // console.log(this.$route.path);
  },
  watch: {
    //  this.$route.path
    '$route.path': function (newVal, oldVal) {
      console.log(newVal + ' --- ' + oldVal)
      if('/' == newVal) {
        this.active = 'cart'
        this.dhIcon = this.icon_dh;
        this.myIcon = this.icon_my2;
      } else if('/my' == newVal) {
        this.active = 'my'
        this.dhIcon = this.icon_dh2;
        this.myIcon = this.icon_my;
      }
    }
  },
  methods: {
    onChange(index) {
      console.log(index)
    }
  }
}
</script>
 
<style lang = "less" scoped>
  
</style>