import axios from 'axios'		//引入axios
import { Toast } from 'vant'	//引入Toast

function upLoaderImg (file) {	//file为 你读取成功的回调文件信息
    //new 一个FormData格式的参数
    console.log(file);
    let params = new FormData()
    params.append('file', file)
    let config = {
        headers: { //添加请求头
            'Content-Type': 'multipart/form-data'
        }
    }
    const token = localStorage.getItem('token')
    if (token) {
        config.headers['Authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return new Promise((resolve, reject) => {
        //把 uploadUrl 换成自己的 上传路径
        axios.post(process.env.VUE_APP_BASE_API + '/oss/upload', params, config).then(res => {
            if (res && res.data && res.data['code'] == 0) {				//如果为真 resolve出去
                resolve(res.data)
            } else {
                //否则 Toast 提示
                Toast.fail(res.data['msg'])
                reject(res.data)
            }
        }).catch(err => {
            Toast.fail('系统异常')
            reject(err)
        });
    })
}
export default upLoaderImg