import request from "@/utils/request";
import global from "@/utils/global";

export function queryCommodityListByPageForRegion(params) {
    return request({
        url: global.URI_PREFIX + '/commodity/queryCommodityListByPageForRegion',
        method: 'get',
        params: params
    });
};

export function queryCommodityListByPageForStore(params) {
    return request({
        url: global.URI_PREFIX + '/commodity/queryCommodityListByPageForStore',
        method: 'get',
        params: params
    });
};

export function queryExchangeCommodityListByPageForStore(params) {
    return request({
        url: global.URI_PREFIX + '/commodity/queryExchangeCommodityListByPageForStore',
        method: 'get',
        params: params
    });
};

export function getStockList() {
    return request({
        url: global.URI_PREFIX + '/stockweb/getStock',
        method: 'post'
    });
};

export function enableCommodity(commodityCode) {
    return request({
        url: global.URI_PREFIX + '/commodity/enableCommodity',
        method: 'post',
        params: { commodityCode: commodityCode }
    });
};

export function disableCommodity(commodityCode) {
    return request({
        url: global.URI_PREFIX + '/commodity/disableCommodity',
        method: 'post',
        params: { commodityCode: commodityCode }
    });
};
