<template>
  <div class="orderByTerminator">
    <img alt="banner" :src="bannerjpg" style="width: 100%;" />
    <van-pull-refresh
        v-model="isLoading"
        success-text="刷新成功"
        @refresh="onRefresh"
    >
      <van-grid :column-num="3">
        <van-grid-item :icon="icon_checkPending" text="待审核" :badge="checkPendingBadge" @click="doTodoOrder(2)" />
        <van-grid-item :icon="icon_dispatched" text="待收货" :badge="dispatchedBadge" @click="doTodoOrder(3)" />
        <van-grid-item :icon="icon_allOrder" text="全部订单" @click="doTodoOrder(5)" />
      </van-grid>
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <van-card
            :price="item.price"
            :desc="item.desc"
            :title="item.commodityName"
            :thumb="item.thumb"
            v-for="(item,index) in cardList" :key="index"
        >
          <template slot="bottom">  <!-- 自定义底部，为了实现商品数量功能 -->
            <div class="num" style="float:right;margin-top: -5px;">
              <van-field name="stepper">
                <template #input>
                  <van-stepper v-model="item.num" integer min="0" @change="onNumChange(item)" />
                </template>
              </van-field>
            </div>
          </template>
        </van-card>
      </van-list>
    </van-pull-refresh>

    <div class="cart-submit--fixed" style="background: #FFF;width: 100%;z-index: 3001;">
      <div class="selected-order" style="width: 68%; float: left;" @click="onShowSelectedOrderList">
        <van-icon name="cart-o" :color="showAddedList?'#8e8efa':'rgb(100 101 103)'" />&nbsp;&nbsp;
        <span :style="showAddedList?'vertical-align: middle;color:#8e8efa;':'vertical-align: middle;color:rgb(100 101 103);'">已选商品&nbsp;({{ selectedNum }})</span>
      </div>
      <div class="btn_order_submit" style="width: 32%; float: right;">
        <van-button type="info" :disabled="selectedNum == 0" @click="onAddCartSubmit" style="height: 40px;width: 100%;border: none;border-radius: 0;">提交</van-button>
      </div>
    </div>

    <van-popup
        class="selectedPopup"
        v-model="showAddedList"
        closeable
        position="bottom"
        :style="{ height: 'auto' }">
      <div style="height: 55px;line-height: 55px;">已选商品</div>
      <van-list
          v-model="selectedLoading"
          :finished="selectedFinished"
      >
        <van-card
            :num="item.num"
            :price="item.price"
            :desc="item.desc"
            :title="item.commodityName"
            :thumb="item.thumb"
            v-for="(item,index) in addCardList" :key="index"
        />
      </van-list>
      <van-cell title="订单金额：" :value="selectedFinishedText" value-class="selectedAmt" style="padding: 4px 20px;" />
      <van-cell title="佣金金额：" :value="selectedCommissionText" value-class="selectedAmt" style="padding: 4px 20px;" />
      <van-cell title="实际金额：" :value="selectedRealAmtText" value-class="selectedAmt" style="padding: 4px 20px;" />
    </van-popup>

    <van-overlay :show="submitLoadingShow" z-index="3002">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;" @click.stop>
        <div style="width: 120px;height: 120px;">
          <van-loading type="spinner" color="#1989fa" vertical>处理中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import bannerjpg from "@/assets/banner.jpg";
import icon_checkPending from "@/assets/icon_checkPending.png";
import icon_dispatched from "@/assets/icon_dispatched.png";
import icon_allOrder from "@/assets/icon_allOrder.png";
import { getCommissionRate, getOrderNum4Wait, orderGoods } from "@/api/order";
import { queryCommodityListByPageForStore } from "@/api/commodity";

export default {
  name: "OrderByTerminator",
  props: {
    msg: String
  },
  data() {
    return {
      icon_allOrder: icon_allOrder,
      bannerjpg: bannerjpg,
      icon_checkPending: icon_checkPending,
      icon_dispatched: icon_dispatched,
      checkPendingByMeBadge: '',
      checkPendingBadge: '',
      dispatchedBadge: '',
      shippedBadge: '',
      loading: false,
      finished: false,
      cardList: [],
      pageNo: 0,
      pageSize: 10,
      selectedNum: 0,
      addCardList: {},
      showAddedList: false,
      selectedLoading: true,
      selectedFinished: true,
      selectedFinishedText: '',
      selectedCommissionText: '',
      selectedRealAmtText: '',
      isLoading: false,
      commissionRate: 0,
      submitLoadingShow: false
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      // 获取佣金比例
      getCommissionRate().then(r => {
        this.commissionRate = r.data;
      });
      // 获取待处理相关数量
      getOrderNum4Wait().then(response => {
        let data = response.data;
        // 待审核
        this.checkPendingBadge = data['tobeReviewed'];
        // 待收货
        this.dispatchedBadge = data['tobeReceived'];
      });
    },
    onRefresh() {
      this.fetchData();

      this.cardList = [];
      this.pageNo = 0;
      this.selectedNum = 0;
      this.addCardList = {};
      this.loading=false;
      this.finished=false;
      this.onLoad();
    },
    doTodoOrder(type) {
      console.log(type);
      if(type == 2) {
        this.$router.push({path: '/reviewed'});
      } else if(type == 3) {
        this.$router.push({path: '/received'});
      } else if(type == 5) {
        this.$router.push({path: '/allOrder'});
      }
    },
    onLoad() {
      // 异步更新数据
      // 列表数据
      this.pageNo = this.pageNo + 1;
      queryCommodityListByPageForStore({pageNo:this.pageNo,pageSize:this.pageSize}).then(response => {
        // console.log(response);
        let data = response.data;
        let records = data.records;
        let current = data.current;
        let pages = data.pages;
        // console.log(records);
        if(records) {
          for(let i in records) {
            // console.log(records[i]);
            let obj = {};
            obj.id = records[i].id;
            obj.commodityName = records[i].commodityName;
            obj.commodityCode = records[i].commodityCode;
            obj.desc = '商品编码：' + records[i].commodityCode;
            obj.price = records[i].commodityPrice;
            obj.thumb = records[i].commodityImg;
            obj.statusFlag = records[i].status == 1?true:false;
            obj.num = 0;
            this.cardList.push(obj);
          };
        }

        // 加载状态结束
        this.loading = false;

        // 最后一页
        if(current == pages) {
          // 数据全部加载完成
          this.finished = true;
        }

        // 如果是下拉刷新，加载完数据也变成false,不是下拉刷新本来就是false设置也无妨
        this.isLoading = false;
      });
    },
    onNumChange(item) {
      // console.log(item);
      // console.log(item.num);
      if(item.num && item.num > 0) {
        this.addCardList[item.commodityCode] = item;
      } else {
        delete this.addCardList[item.commodityCode]
      }
      // console.log(this.addCardList);
      this.selectedNum = Object.keys(this.addCardList).length;
      // console.log(this.selectedNum);
    },
    onAddCartSubmit() {
      if(this.selectedNum > 0 && !this.submitLoadingShow) {
        // 要防止二次提交
        this.submitLoadingShow = true
        // console.log(this.addCardList);
        let params = {};
        let orderPrice = 0;
        let orderType = 1;
        let commodityList = [];
        for(let code in this.addCardList) {
          let cardItem = this.addCardList[code];
          // console.log(this.addCardList[code]);
          orderPrice += cardItem.price * cardItem.num;
          commodityList.push({amount: cardItem.num, commodityPrice: cardItem.price, commodityCode: cardItem.commodityCode, commodityName: cardItem.commodityName});
        }
        params['orderType'] = orderType;
        params['orderPrice'] = orderPrice;
        params['commodityList'] = commodityList;
        // console.log(params)
        orderGoods(params).then(response => {
          console.log(response);
          this.submitLoadingShow = false
          this.$router.push({path: '/reviewed'});
        }).catch(err => {
          console.log(err);
          this.submitLoadingShow = false
        });
      }
    },
    onShowSelectedOrderList() {
      if(this.selectedNum > 0) {
        if(this.showAddedList) {
          this.showAddedList = false;
        } else {
          let orderPrice = 0;
          for(let code in this.addCardList) {
            let cardItem = this.addCardList[code];
            orderPrice += cardItem.price * cardItem.num;
          }
          // 计算佣金(百分之七)
          let commission = (orderPrice * this.commissionRate).toFixed(2);
          // 实际金额
          let realAmt = orderPrice - commission;
          this.selectedFinishedText = '￥' + orderPrice;
          this.selectedCommissionText = '￥' + commission;
          this.selectedRealAmtText = '￥'+realAmt;
          this.showAddedList = true;
        }
      }
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.banner {
  margin: 10px auto 0;
  width: calc(100% - 20px);
  border-radius: 8px;
}
.home .van-card__price {
  display: inline-block;
}
.home .van-card__thumb {
  width: 60px;
  height: 60px;
}
.home .van-card__content {
  min-height: 0;
}
.van-card__content {
  border-bottom: 1px solid #ece8e8;
  padding-bottom: 6px;
}
.van-cell {
  padding: 4px 0;
}
.van-card__price-integer {
  font-size: 18px;
}
.van-card__price-currency {
  font-size: 15px;
}
.van-card:not(:first-child) {
  margin-top: 0;
}
.cart-submit--fixed {
  position: fixed;
  bottom: 50px;
  left: 0;
  height: 40px;
  line-height: 40px;
}
.van-button::before {
  display: none;
}
.van-button__content::before {
  display: none;
}
.van-icon.van-icon-cart-o {
  font-size: 20px;
  vertical-align: middle;
}
.van-popup--bottom {
  bottom: 90px;
  max-height: 70%;
  border-bottom: 1px solid #f5f1f1;
}
.van-cell__value.selectedAmt {
  color:#655454;
}
.selectedPopup .van-cell__title {
  flex: 3;
  text-align: right;
}
</style>
