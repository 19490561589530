import request from "@/utils/request";
import global from "@/utils/global";
import upLoaderImg from "@/utils/upLoaderImg";

export function getOrderNum4Wait() {
    return request({
        url: global.URI_PREFIX + '/order/getOrderNum4Wait',
        method: 'get'
    });
};

export function orderGoods(params) {
    return request({
        url: global.URI_PREFIX + '/order/orderGoods',
        method: 'post',
        data: params
    });
};

export function exchangeGoods(params) {
    return request({
        url: global.URI_PREFIX + '/order/exchangeGoods',
        method: 'post',
        data: params
    });
};

export function countPriceAndCommission(params) {
    return request({
        url: global.URI_PREFIX + '/order/countPriceAndCommission',
        method: 'get',
        params: params
    });
};

export function getOrderGoodsList(params) {
    return request({
        url: global.URI_PREFIX + '/order/getOrderGoodsList',
        method: 'get',
        params: params
    });
};

export function getOrderList(type, pageNo, pageSize) {
    return request({
        url: global.URI_PREFIX + '/order/getOrderList',
        method: 'get',
        params: {type:type, pageNo:pageNo, pageSize:pageSize}
    });
};

export function getOrderInfoByOrderNo(orderNo) {
    return request({
        url: global.URI_PREFIX + '/order/getOrderInfoByOrderNo',
        method: 'get',
        params: {orderNo:orderNo}
    });
};

export function myTaskList(type, pageNo, pageSize) {
    return request({
        url: global.URI_PREFIX + '/auditTask/myTaskList',
        method: 'get',
        params: {type:type, pageNo:pageNo, pageSize:pageSize}
    });
};

export function approvePass(id, remark) {
    return request({
        url: global.URI_PREFIX + '/auditTask/approvePass',
        method: 'post',
        params: { id: id, remark: remark }
    });
};

export function reject(id, remark) {
    return request({
        url: global.URI_PREFIX + '/auditTask/reject',
        method: 'post',
        params: { id: id, remark: remark }
    });
};

export function cancelOrderGoods(orderNo) {
    return request({
        url: global.URI_PREFIX + '/order/cancelOrderGoods',
        method: 'post',
        params: {orderNo:orderNo}
    });
};

export function receipt(orderNo, payType) {
    return request({
        url: global.URI_PREFIX + '/order/receipt',
        method: 'post',
        // 支付方式（1现金，2扫码）
        params: {orderNo:orderNo, payType: payType}
    });
};

export function confirmSend(orderNo) {
    return request({
        url: global.URI_PREFIX + '/order/confirmSend',
        method: 'post',
        params: {orderNo:orderNo}
    });
};
export function returnGoods(orderNo) {
    return request({
        url: global.URI_PREFIX + '/order/returnGoods',
        method: 'post',
        params: {orderNo:orderNo}
    });
};

export function timeOutStatus(params) {
    return request({
        url: global.URI_PREFIX + '/order/timeOutStatus',
        method: 'post',
        params: params
    });
};
export function uploadPayImage(orderNo, payImage) {
    return request({
        url: global.URI_PREFIX + '/order/uploadPayImage',
        method: 'post',
        params: {orderNo: orderNo, payImage: payImage}
    });
};

export function ossUpload(file) {
    return upLoaderImg(file);
};

export function getCommissionRate() {
    return request({
        url: global.URI_PREFIX + '/order/getCommissionRate',
        method: 'get'
    });
};


