import request from "@/utils/request";
import global from "@/utils/global";

export function doPhoneCheck(data) {
    return request({
        url: global.URI_PREFIX + '/user/isRegistered',
        method: 'post',
        params: { phone: data.phone }
    });
};

export function doLogin(data) {
    return request({
        url: global.URI_PREFIX + '/user/login',
        method: 'post',
        params: { phone: data.phone, code: data.code }
    });
};

export function getRegionManagerOptions(params) {
    return request({
        url: global.URI_PREFIX + '/user/getRegionManagerOptions',
        method: 'get',
        params: params
    });
};

export function setRegisterInfo(data) {
    return request({
        url: global.URI_PREFIX + '/user/setRegisterInfo',
        method: 'post',
        data: data
    });
};

export function updateRegionManager(params) {
    return request({
        url: global.URI_PREFIX + '/user/updateRegionManager',
        method: 'post',
        params: params
    });
};

export function getInfo() {
    return request({
        url: global.URI_PREFIX + '/user/getInfo',
        method: 'get'
    });
};

export function getRejectRemark() {
    return request({
        url: global.URI_PREFIX + '/user/getRejectRemark',
        method: 'get'
    });
};

export function getStoreInfo() {
    return request({
        url: global.URI_PREFIX + '/user/getStoreInfo',
        method: 'get'
    });
};

export function updateShopInfo(params) {
    return request({
        url: global.URI_PREFIX + '/user/updateShopInfo',
        method: 'post',
        data: params
    });
};

export function getPersonalCenterInfo() {
    return request({
        url: global.URI_PREFIX + '/user/getPersonalCenterInfo',
        method: 'post'
    });
};

/**
 * 用户账号密码登录
 * @param {} data 
 * phone 账号
 * password 密码
 * @returns 
 */
export function userLogin(data) {
    return request({
        url: global.URI_PREFIX + '/user/userLogin',
        method: 'post',
        params: data
    });
};
/**
 * 用户账号密码登录
 * @param {phone} data phone 手机号
 * @returns 
 */
export function sendCode(data) {
    return request({
        url: global.URI_PREFIX + '/user/sendCode',
        method: 'post',
        params: data
    });
};

/**
 * 注册用户
 * @param {phone 手机号
 *         password 密码
 *         confirmPassword 确认密码
 *         code 验证码} data phone 手机号
 * @returns 
 */
 export function register(data) {
    return request({
        url: global.URI_PREFIX + '/user/register',
        method: 'post',
        params: data
    });
};

export function getAreaList() {
    const areaResult =  {
        "code": 0,
        "msg": "",
        "data": {
            "county_list": {
                "110101": "东城区",
                "110102": "西城区",
                "110103": "朝阳区",
                "110104": "丰台区",
                "110105": "石景山区",
                "110106": "海淀区",
                "110107": "门头沟区",
                "110108": "房山区",
                "110109": "通州区",
                "110110": "顺义区",
                "110111": "昌平区",
                "110112": "大兴区",
                "110113": "怀柔区",
                "110114": "平谷区",
                "110115": "密云县",
                "110116": "延庆县",
                "120101": "黄浦区",
                "120102": "卢湾区",
                "120103": "徐汇区",
                "120104": "长宁区",
                "120105": "静安区",
                "120106": "普陀区",
                "120107": "闸北区",
                "120108": "虹口区",
                "120109": "杨浦区",
                "120110": "闵行区",
                "120111": "宝山区",
                "120112": "嘉定区",
                "120113": "浦东新区",
                "120114": "金山区",
                "120115": "松江区",
                "120116": "青浦区",
                "120117": "奉贤区",
                "120118": "崇明县",
                "130101": "和平区",
                "130102": "河东区",
                "130103": "河西区",
                "130104": "南开区",
                "130105": "河北区",
                "130106": "红桥区",
                "130107": "塘沽区",
                "130108": "汉沽区",
                "130109": "大港区",
                "130110": "东丽区",
                "130111": "西青区",
                "130112": "津南区",
                "130113": "北辰区",
                "130114": "武清区",
                "130115": "宝坻区",
                "130116": "宁河县",
                "130117": "静海县",
                "130118": "蓟县",
                "130119": "滨海新区",
                "140101": "海拉尔区",
                "140102": "鄂温克族自治旗",
                "140103": "陈巴尔虎旗",
                "140104": "牙克石市",
                "140105": "扎兰屯市",
                "140106": "阿荣旗",
                "140107": "莫力达瓦自治旗",
                "140108": "满洲里市",
                "140109": "鄂伦春自治旗",
                "140110": "新巴尔虎左旗",
                "140111": "新巴尔虎右旗",
                "140112": "额尔古纳市",
                "140113": "根河市",
                "140201": "新城区",
                "140202": "回民区",
                "140203": "玉泉区",
                "140204": "赛罕区",
                "140205": "土左旗",
                "140206": "托克托县",
                "140207": "和林格尔县",
                "140208": "清水河县",
                "140209": "武川县",
                "140301": "东河区",
                "140302": "昆都仑区",
                "140303": "青山区",
                "140304": "石拐区",
                "140305": "白云矿区",
                "140306": "九原区",
                "140307": "土右旗",
                "140308": "固阳县",
                "140309": "达茂联合旗",
                "140401": "海勃湾区",
                "140402": "海南区",
                "140403": "乌达区",
                "140501": "集宁区",
                "140502": "卓资县",
                "140503": "集宁区",
                "140504": "兴和县",
                "140505": "察哈尔右翼前旗",
                "140506": "凉城县",
                "140507": "察哈尔右翼中旗",
                "140508": "察哈尔右翼后旗",
                "140509": "商都县",
                "140510": "四子王旗",
                "140511": "丰镇市",
                "140512": "化德县",
                "140601": "科尔沁区",
                "140602": "科左中旗",
                "140603": "科左后旗",
                "140604": "开鲁县",
                "140605": "库伦旗",
                "140606": "奈曼旗",
                "140607": "扎鲁特旗",
                "140608": "霍林郭勒市",
                "140701": "红山区",
                "140702": "松山区",
                "140703": "元宝山区",
                "140704": "宁城县",
                "140705": "林西县",
                "140706": "阿鲁科尔沁旗",
                "140707": "巴林左旗",
                "140708": "巴林右旗",
                "140709": "克什克腾旗",
                "140710": "翁牛特旗",
                "140711": "喀喇沁旗",
                "140712": "敖汉旗",
                "140801": "东胜区",
                "140802": "铁西区",
                "140803": "康巴什新区",
                "140804": "伊旗",
                "140805": "准格尔旗",
                "140806": "乌审旗",
                "140807": "杭锦旗",
                "140808": "鄂托克旗",
                "140809": "鄂托克前旗",
                "140810": "达旗",
                "140901": "临河区",
                "140902": "五原县",
                "140903": "磴口县",
                "140904": "乌拉特前旗",
                "140905": "乌拉特中旗",
                "140906": "乌拉特后旗",
                "140907": "杭锦后旗",
                "141001": "锡林浩特市",
                "141002": "阿巴嘎旗",
                "141003": "西乌珠穆沁旗",
                "141004": "二连浩特市",
                "141005": "苏尼特左旗",
                "141006": "苏尼特右旗",
                "141007": "东乌珠穆沁旗",
                "141008": "太仆寺旗",
                "141009": "镶黄旗",
                "141010": "正镶白旗",
                "141011": "正蓝旗",
                "141012": "多伦县",
                "141101": "乌兰浩特市",
                "141102": "阿尔山市",
                "141103": "科右前旗",
                "141104": "科右中旗",
                "141105": "扎赉特旗",
                "141106": "突泉县",
                "141201": "阿拉善左旗",
                "141202": "阿拉善右旗",
                "141203": "额济纳旗",
                "150101": "朔城区",
                "150102": "平鲁区",
                "150103": "山阴县",
                "150104": "应县",
                "150105": "右玉县",
                "150106": "怀仁县",
                "150201": "忻府区",
                "150202": "定襄县",
                "150203": "五台县",
                "150204": "代县",
                "150205": "繁峙县",
                "150206": "宁武县",
                "150207": "静乐县",
                "150208": "神池县",
                "150209": "五寨县",
                "150210": "岢岚县",
                "150211": "河曲县",
                "150212": "保德县",
                "150213": "偏关县",
                "150214": "原平市",
                "150301": "小店区",
                "150302": "迎泽区",
                "150303": "杏花岭区",
                "150304": "尖草坪区",
                "150305": "万柏林区",
                "150306": "晋源区",
                "150307": "清徐县",
                "150308": "阳曲县",
                "150309": "娄烦县",
                "150310": "古交市",
                "150401": "城区",
                "150402": "矿区",
                "150403": "南郊区",
                "150404": "新荣区",
                "150405": "阳高县",
                "150406": "天镇县",
                "150407": "广灵县",
                "150408": "灵丘县",
                "150409": "浑源县",
                "150410": "左云县",
                "150411": "大同县",
                "150412": "御东区",
                "150501": "城区",
                "150502": "矿区",
                "150503": "郊区",
                "150504": "平定县",
                "150505": "盂县",
                "150601": "榆次区",
                "150602": "榆社县",
                "150603": "左权县",
                "150604": "和顺县",
                "150605": "昔阳县",
                "150606": "寿阳县",
                "150607": "太谷县",
                "150608": "祁县",
                "150609": "平遥县",
                "150610": "灵石县",
                "150611": "介休市",
                "150701": "城区",
                "150702": "郊区",
                "150703": "长治县",
                "150704": "襄垣县",
                "150705": "屯留县",
                "150706": "平顺县",
                "150707": "黎城县",
                "150708": "壶关县",
                "150709": "长子县",
                "150710": "武乡县",
                "150711": "沁县",
                "150712": "沁源县",
                "150713": "潞城市",
                "150714": "高新区",
                "150801": "城区",
                "150802": "沁水县",
                "150803": "阳城县",
                "150804": "陵川县",
                "150805": "泽州县",
                "150806": "高平市",
                "150901": "尧都区",
                "150902": "曲沃县",
                "150903": "翼城县",
                "150904": "襄汾县",
                "150905": "洪洞县",
                "150906": "古县",
                "150907": "安泽县",
                "150908": "浮山县",
                "150909": "吉县",
                "150910": "乡宁县",
                "150911": "大宁县",
                "150912": "隰县",
                "150913": "永和县",
                "150914": "蒲县",
                "150915": "汾西县",
                "150916": "侯马市",
                "150917": "霍州市",
                "151001": "离石区",
                "151002": "文水县",
                "151003": "交城县",
                "151004": "兴县",
                "151005": "临县",
                "151006": "柳林县",
                "151007": "石楼县",
                "151008": "岚县",
                "151009": "方山县",
                "151010": "中阳县",
                "151011": "交口县",
                "151012": "孝义市",
                "151013": "汾阳市",
                "151101": "盐湖区",
                "151102": "临猗县",
                "151103": "万荣县",
                "151104": "闻喜县",
                "151105": "稷山县",
                "151106": "新绛县",
                "151107": "绛县",
                "151108": "垣曲县",
                "151109": "夏县",
                "151110": "平陆县",
                "151111": "芮城县",
                "151112": "永济市",
                "151113": "河津市",
                "160101": "邯山区",
                "160102": "丛台区",
                "160103": "复兴区",
                "160104": "峰峰矿区",
                "160105": "邯郸县",
                "160106": "临漳县",
                "160107": "成安县",
                "160108": "大名县",
                "160109": "涉县",
                "160110": "磁县",
                "160111": "肥乡县",
                "160112": "永年县",
                "160113": "邱县",
                "160114": "鸡泽县",
                "160115": "广平县",
                "160116": "馆陶县",
                "160117": "魏县",
                "160118": "曲周县",
                "160119": "武安市",
                "160201": "长安区",
                "160202": "桥东区",
                "160203": "桥西区",
                "160204": "新华区",
                "160205": "井陉矿区",
                "160206": "裕华区",
                "160207": "井陉县",
                "160208": "正定县",
                "160209": "栾城区",
                "160210": "行唐县",
                "160211": "灵寿县",
                "160212": "高邑县",
                "160213": "深泽县",
                "160214": "赞皇县",
                "160215": "无极县",
                "160216": "平山县",
                "160217": "元氏县",
                "160218": "赵县",
                "160219": "辛集市",
                "160220": "藁城区",
                "160221": "晋州市",
                "160222": "新乐市",
                "160223": "鹿泉区",
                "160301": "新市区",
                "160302": "北市区",
                "160303": "南市区",
                "160304": "满城县",
                "160305": "清苑县",
                "160306": "涞水县",
                "160307": "阜平县",
                "160308": "徐水县",
                "160309": "定兴县",
                "160310": "唐县",
                "160311": "高阳县",
                "160312": "容城县",
                "160313": "涞源县",
                "160314": "望都县",
                "160315": "安新县",
                "160316": "易县",
                "160317": "曲阳县",
                "160318": "蠡县",
                "160319": "顺平县",
                "160320": "博野县",
                "160321": "雄县",
                "160322": "涿州市",
                "160323": "定州市",
                "160324": "安国市",
                "160325": "高碑店市",
                "160401": "桥东区",
                "160402": "桥西区",
                "160403": "宣化区",
                "160404": "下花园区",
                "160405": "宣化县",
                "160406": "张北县",
                "160407": "康保县",
                "160408": "沽源县",
                "160409": "尚义县",
                "160410": "蔚县",
                "160411": "阳原县",
                "160412": "怀安县",
                "160413": "万全县",
                "160414": "怀来县",
                "160415": "涿鹿县",
                "160416": "赤城县",
                "160417": "崇礼县",
                "160501": "双桥区",
                "160502": "双滦区",
                "160503": "鹰手营子矿区",
                "160504": "承德县",
                "160505": "兴隆县",
                "160506": "平泉县",
                "160507": "滦平县",
                "160508": "隆化县",
                "160509": "丰宁县",
                "160510": "宽城县",
                "160511": "围场县",
                "160601": "路南区",
                "160602": "芦台汉沽农场",
                "160603": "路北区",
                "160604": "古冶区",
                "160605": "开平区",
                "160606": "丰南区",
                "160607": "丰润区",
                "160608": "滦县",
                "160609": "滦南县",
                "160610": "乐亭县",
                "160611": "迁西县",
                "160612": "玉田县",
                "160613": "曹妃甸新区",
                "160614": "遵化市",
                "160615": "迁安市",
                "160701": "安次区",
                "160702": "广阳区",
                "160703": "固安县",
                "160704": "永清县",
                "160705": "香河县",
                "160706": "大城县",
                "160707": "文安县",
                "160708": "大厂县",
                "160709": "霸州市",
                "160710": "三河市",
                "160711": "开发区",
                "160801": "新华区",
                "160802": "运河区",
                "160803": "沧县",
                "160804": "青县",
                "160805": "东光县",
                "160806": "海兴县",
                "160807": "盐山县",
                "160808": "肃宁县",
                "160809": "南皮县",
                "160810": "吴桥县",
                "160811": "献县",
                "160812": "孟村县",
                "160813": "泊头市",
                "160814": "任丘市",
                "160815": "黄骅市",
                "160816": "河间市",
                "160901": "桃城区",
                "160902": "枣强县",
                "160903": "武邑县",
                "160904": "武强县",
                "160905": "饶阳县",
                "160906": "安平县",
                "160907": "故城县",
                "160908": "景县",
                "160909": "阜城县",
                "160910": "冀州市",
                "160911": "深州市",
                "161001": "桥东区",
                "161002": "桥西区",
                "161003": "邢台县",
                "161004": "临城县",
                "161005": "内丘县",
                "161006": "柏乡县",
                "161007": "隆尧县",
                "161008": "任县",
                "161009": "南和县",
                "161010": "宁晋县",
                "161011": "巨鹿县",
                "161012": "新河县",
                "161013": "广宗县",
                "161014": "平乡县",
                "161015": "威县",
                "161016": "清河县",
                "161017": "临西县",
                "161018": "南宫市",
                "161019": "沙河市",
                "161101": "海港区",
                "161102": "经济技术开发区",
                "161103": "山海关区",
                "161104": "北戴河区",
                "161105": "抚宁县",
                "161106": "卢龙县",
                "161107": "青龙县",
                "161108": "昌黎县",
                "170101": "和平区",
                "170102": "沈河区",
                "170103": "大东区",
                "170104": "皇姑区",
                "170105": "铁西区",
                "170106": "苏家屯区",
                "170107": "东陵区",
                "170108": "沈北新区",
                "170109": "于洪区",
                "170110": "浑南新区",
                "170111": "辽中县",
                "170112": "康平县",
                "170113": "法库县",
                "170114": "新民市",
                "170201": "银州区",
                "170202": "清河区",
                "170203": "铁岭县",
                "170204": "西丰县",
                "170205": "昌图县",
                "170206": "调兵山市",
                "170207": "开原市",
                "170208": "铁岭新城",
                "170301": "中山区",
                "170302": "西岗区",
                "170303": "沙河口区",
                "170304": "甘井子区",
                "170305": "旅顺口区",
                "170306": "金州区",
                "170307": "大连开发区",
                "170308": "普兰店市",
                "170309": "瓦房店市",
                "170310": "庄河市",
                "170311": "长海县",
                "170401": "铁东区",
                "170402": "铁西区",
                "170403": "立山区",
                "170404": "千山区",
                "170405": "台安县",
                "170406": "岫岩县",
                "170407": "海城市",
                "170501": "新抚区",
                "170502": "东洲区",
                "170503": "望花区",
                "170504": "顺城区",
                "170505": "抚顺县",
                "170506": "新宾县",
                "170507": "清原县",
                "170601": "平山区",
                "170602": "溪湖区",
                "170603": "明山区",
                "170604": "南芬区",
                "170605": "本溪县",
                "170606": "桓仁县",
                "170701": "元宝区",
                "170702": "振兴区",
                "170703": "振安区",
                "170704": "宽甸县",
                "170705": "东港市",
                "170706": "凤城市",
                "170801": "古塔区",
                "170802": "凌河区",
                "170803": "太和区",
                "170804": "黑山县",
                "170805": "义县",
                "170806": "凌海市",
                "170807": "北镇市",
                "170808": "松山新区",
                "170901": "站前区",
                "170902": "西市区",
                "170903": "鲅鱼圈区",
                "170904": "老边区",
                "170905": "盖州市",
                "170906": "大石桥市",
                "171001": "海州区",
                "171002": "新邱区",
                "171003": "太平区",
                "171004": "清河门区",
                "171005": "细河区",
                "171006": "阜新县",
                "171007": "彰武县",
                "171101": "白塔区",
                "171102": "文圣区",
                "171103": "宏伟区",
                "171104": "弓长岭区",
                "171105": "太子河区",
                "171106": "辽阳县",
                "171107": "灯塔市",
                "171201": "双塔区",
                "171202": "龙城区",
                "171203": "朝阳县",
                "171204": "建平县",
                "171205": "喀左县",
                "171206": "北票市",
                "171207": "凌源市",
                "171301": "双台子区",
                "171302": "兴隆台区",
                "171303": "大洼县",
                "171304": "盘山县",
                "171401": "连山区",
                "171402": "龙港区",
                "171403": "南票区",
                "171404": "绥中县",
                "171405": "建昌县",
                "171406": "兴城市",
                "180101": "南关区",
                "180102": "宽城区",
                "180103": "朝阳区",
                "180104": "二道区",
                "180105": "绿园区",
                "180106": "双阳区",
                "180107": "农安县",
                "180108": "九台区",
                "180109": "榆树市",
                "180110": "德惠市",
                "180111": "净月开发区",
                "180112": "经济开发区（二道）",
                "180113": "高新开发区",
                "180114": "汽车产业开发区（绿园）",
                "180201": "昌邑区",
                "180202": "龙潭区",
                "180203": "船营区",
                "180204": "丰满区",
                "180205": "永吉县",
                "180206": "蛟河市",
                "180207": "桦甸市",
                "180208": "舒兰市",
                "180209": "磐石市",
                "180301": "延吉市",
                "180302": "图们市",
                "180303": "敦化市",
                "180304": "珲春市",
                "180305": "龙井市",
                "180306": "和龙市",
                "180307": "汪清县",
                "180308": "安图县",
                "180401": "铁西区",
                "180402": "铁东区",
                "180403": "梨树县",
                "180404": "伊通县",
                "180405": "公主岭市",
                "180406": "双辽市",
                "180501": "东昌区",
                "180502": "二道江区",
                "180503": "通化县",
                "180504": "辉南县",
                "180505": "柳河县",
                "180506": "梅河口市",
                "180507": "集安市",
                "180601": "洮北区",
                "180602": "镇赉县",
                "180603": "通榆县",
                "180604": "洮南市",
                "180605": "大安市",
                "180701": "龙山区",
                "180702": "西安区",
                "180703": "东丰县",
                "180704": "东辽县",
                "180801": "宁江区",
                "180802": "前郭县",
                "180803": "长岭县",
                "180804": "乾安县",
                "180805": "扶余市",
                "180901": "浑江区",
                "180902": "江源区",
                "180903": "抚松县",
                "180904": "靖宇县",
                "180905": "长白县",
                "180906": "临江市",
                "190101": "道里区",
                "190102": "南岗区",
                "190103": "道外区",
                "190104": "平房区",
                "190105": "松北区",
                "190106": "香坊区",
                "190107": "呼兰区",
                "190108": "阿城区",
                "190109": "依兰县",
                "190110": "方正县",
                "190111": "宾县",
                "190112": "巴彦县",
                "190113": "木兰县",
                "190114": "通河县",
                "190115": "延寿县",
                "190116": "双城市",
                "190117": "尚志市",
                "190118": "五常市",
                "190201": "龙沙区",
                "190202": "建华区",
                "190203": "铁锋区",
                "190204": "昂昂溪区",
                "190205": "富拉尔基区",
                "190206": "碾子山区",
                "190207": "梅里斯区",
                "190208": "龙江县",
                "190209": "依安县",
                "190210": "泰来县",
                "190211": "甘南县",
                "190212": "富裕县",
                "190213": "克山县",
                "190214": "克东县",
                "190215": "拜泉县",
                "190216": "讷河市",
                "190301": "东安区",
                "190302": "阳明区",
                "190303": "爱民区",
                "190304": "西安区",
                "190305": "东宁县",
                "190306": "林口县",
                "190307": "绥芬河市",
                "190308": "海林市",
                "190309": "宁安市",
                "190310": "穆棱市",
                "190401": "向阳区",
                "190402": "前进区",
                "190403": "东风区",
                "190404": "郊区",
                "190405": "桦南县",
                "190406": "桦川县",
                "190407": "汤原县",
                "190408": "抚远县",
                "190409": "同江市",
                "190410": "富锦市",
                "190501": "北林区",
                "190502": "望奎县",
                "190503": "兰西县",
                "190504": "青冈县",
                "190505": "庆安县",
                "190506": "明水县",
                "190507": "绥棱县",
                "190508": "安达市",
                "190509": "肇东市",
                "190510": "海伦市",
                "190601": "爱辉区",
                "190602": "嫩江县",
                "190603": "逊克县",
                "190604": "孙吴县",
                "190605": "北安市",
                "190606": "五大连池市",
                "190701": "加格达奇区",
                "190702": "松岭区",
                "190703": "新林区",
                "190704": "呼中区",
                "190705": "呼玛县",
                "190706": "塔河县",
                "190707": "漠河县",
                "190801": "伊春区",
                "190802": "南岔区",
                "190803": "友好区",
                "190804": "西林区",
                "190805": "翠峦区",
                "190806": "新青区",
                "190807": "美溪区",
                "190808": "金山屯区",
                "190809": "五营区",
                "190810": "乌马河区",
                "190811": "汤旺河区",
                "190812": "带岭区",
                "190813": "乌伊岭区",
                "190814": "红星区",
                "190815": "上甘岭区",
                "190816": "嘉荫县",
                "190817": "铁力市",
                "190901": "萨尔图区",
                "190902": "龙凤区",
                "190903": "让胡路区",
                "190904": "红岗区",
                "190905": "大同区",
                "190906": "肇州县",
                "190907": "肇源县",
                "190908": "林甸县",
                "190909": "杜尔伯特县",
                "191001": "新兴区",
                "191002": "桃山区",
                "191003": "茄子河区",
                "191004": "勃利县",
                "191101": "鸡冠区",
                "191102": "恒山区",
                "191103": "滴道区",
                "191104": "梨树区",
                "191105": "城子河区",
                "191106": "麻山区",
                "191107": "鸡东县",
                "191108": "虎林市",
                "191109": "密山市",
                "191201": "向阳区",
                "191202": "工农区",
                "191203": "南山区",
                "191204": "兴安区",
                "191205": "东山区",
                "191206": "兴山区",
                "191207": "萝北县",
                "191208": "绥滨县",
                "191301": "尖山区",
                "191302": "岭东区",
                "191303": "四方台区",
                "191304": "宝山区",
                "191305": "集贤县",
                "191306": "友谊县",
                "191307": "宝清县",
                "191308": "饶河县",
                "200101": "玄武区",
                "200102": "秦淮区",
                "200103": "建邺区",
                "200104": "鼓楼区",
                "200105": "栖霞区",
                "200106": "雨花台区",
                "200107": "浦口区",
                "200108": "六合区",
                "200109": "江宁区",
                "200110": "溧水区",
                "200111": "高淳区",
                "200201": "崇安区",
                "200202": "南长区",
                "200203": "北塘区",
                "200204": "锡山区",
                "200205": "惠山区",
                "200206": "滨湖区",
                "200207": "无锡新区",
                "200208": "江阴市",
                "200209": "宜兴市",
                "200301": "京口区",
                "200302": "润州区",
                "200303": "丹徒区",
                "200304": "丹阳市",
                "200305": "扬中市",
                "200306": "句容市",
                "200401": "虎丘区",
                "200402": "吴中区",
                "200403": "相城区",
                "200404": "常熟市",
                "200405": "张家港市",
                "200406": "昆山市",
                "200407": "吴江区",
                "200408": "太仓市",
                "200409": "工业园区",
                "200410": "姑苏区",
                "200501": "港闸区",
                "200502": "崇川区",
                "200503": "南通经济技术开发区",
                "200504": "海安县",
                "200505": "如皋市",
                "200506": "如东县",
                "200507": "通州区",
                "200508": "海门市",
                "200509": "启东市",
                "200601": "广陵区",
                "200602": "邗江区",
                "200603": "宝应县",
                "200604": "仪征市",
                "200605": "高邮市",
                "200606": "江都区",
                "200607": "开发区",
                "200701": "亭湖区",
                "200702": "盐都区",
                "200703": "响水县",
                "200704": "滨海县",
                "200705": "阜宁县",
                "200706": "射阳县",
                "200707": "建湖县",
                "200708": "大丰市",
                "200709": "东台市",
                "200801": "鼓楼区",
                "200802": "云龙区",
                "200803": "贾汪区",
                "200804": "泉山区",
                "200805": "丰县",
                "200806": "沛县",
                "200807": "铜山区",
                "200808": "睢宁县",
                "200809": "新沂市",
                "200810": "邳州市",
                "200901": "清河区",
                "200902": "淮安区",
                "200903": "淮阴区",
                "200904": "清浦区",
                "200905": "涟水县",
                "200906": "洪泽县",
                "200907": "盱眙县",
                "200908": "金湖县",
                "201001": "海州区",
                "201002": "连云区",
                "201003": "赣榆区",
                "201004": "东海县",
                "201005": "灌云县",
                "201006": "灌南县",
                "201101": "天宁区",
                "201102": "钟楼区",
                "201103": "新北区",
                "201104": "戚墅堰区",
                "201105": "武进区",
                "201106": "金坛市",
                "201107": "溧阳市",
                "201201": "海陵区",
                "201202": "高港区",
                "201203": "兴化市",
                "201204": "靖江市",
                "201205": "泰兴市",
                "201206": "姜堰区",
                "201301": "宿城区",
                "201302": "宿豫区",
                "201303": "沭阳县",
                "201304": "泗阳县",
                "201305": "泗洪县",
                "210101": "琅琊区",
                "210102": "南谯区",
                "210103": "来安县",
                "210104": "全椒县",
                "210105": "定远县",
                "210106": "凤阳县",
                "210107": "天长市",
                "210108": "明光市",
                "210201": "瑶海区",
                "210202": "庐阳区",
                "210203": "蜀山区",
                "210204": "包河区",
                "210205": "长丰县",
                "210206": "肥东县",
                "210207": "肥西县",
                "210208": "政务文化新区",
                "210209": "新站区",
                "210210": "滨湖新区",
                "210211": "巢湖市",
                "210212": "庐江县",
                "210213": "高新区",
                "210301": "龙子湖区",
                "210302": "蚌山区",
                "210303": "禹会区",
                "210304": "淮上区",
                "210305": "怀远县",
                "210306": "五河县",
                "210307": "固镇县",
                "210401": "镜湖区",
                "210402": "弋江区",
                "210403": "鸠江区",
                "210404": "三山区",
                "210405": "芜湖县",
                "210406": "繁昌县",
                "210407": "南陵县",
                "210408": "无为县",
                "210501": "大通区",
                "210502": "田家庵区",
                "210503": "谢家集区",
                "210504": "八公山区",
                "210505": "潘集区",
                "210506": "凤台县",
                "210601": "花山区",
                "210602": "雨山区",
                "210603": "当涂县",
                "210604": "含山县",
                "210605": "和县",
                "210701": "迎江区",
                "210702": "大观区",
                "210703": "宜秀区",
                "210704": "怀宁县",
                "210705": "枞阳县",
                "210706": "潜山县",
                "210707": "太湖县",
                "210708": "宿松县",
                "210709": "望江县",
                "210710": "岳西县",
                "210711": "桐城市",
                "210801": "埇桥区",
                "210802": "砀山县",
                "210803": "萧县",
                "210804": "灵璧县",
                "210805": "泗县",
                "210901": "颍州区",
                "210902": "颍东区",
                "210903": "颍泉区",
                "210904": "临泉县",
                "210905": "太和县",
                "210906": "阜南县",
                "210907": "颍上县",
                "210908": "界首市",
                "211001": "屯溪区",
                "211002": "黄山区",
                "211003": "黄山风景区",
                "211004": "徽州区",
                "211005": "歙县",
                "211006": "休宁县",
                "211007": "黟县",
                "211008": "祁门县",
                "211101": "谯城区",
                "211102": "涡阳县",
                "211103": "蒙城县",
                "211104": "利辛县",
                "211201": "杜集区",
                "211202": "相山区",
                "211203": "烈山区",
                "211204": "濉溪县",
                "211301": "铜官山区",
                "211302": "狮子山区",
                "211303": "郊区",
                "211304": "铜陵县",
                "211401": "宣州区",
                "211402": "郎溪县",
                "211403": "广德县",
                "211404": "泾县",
                "211405": "绩溪县",
                "211406": "旌德县",
                "211407": "宁国市",
                "211501": "金安区",
                "211502": "裕安区",
                "211503": "寿县",
                "211504": "霍邱县",
                "211505": "舒城县",
                "211506": "金寨县",
                "211507": "霍山县",
                "211601": "贵池区",
                "211602": "东至县",
                "211603": "石台县",
                "211604": "青阳县",
                "220101": "牡丹区",
                "220102": "曹县",
                "220103": "单县",
                "220104": "成武县",
                "220105": "巨野县",
                "220106": "郓城县",
                "220107": "鄄城县",
                "220108": "定陶县",
                "220109": "东明县",
                "220201": "历下区",
                "220202": "市中区",
                "220203": "槐荫区",
                "220204": "天桥区",
                "220205": "历城区",
                "220206": "长清区",
                "220207": "平阴县",
                "220208": "济阳县",
                "220209": "商河县",
                "220210": "章丘市",
                "220301": "市南区",
                "220302": "市北区",
                "220303": "黄岛区",
                "220304": "崂山区",
                "220305": "李沧区",
                "220306": "城阳区",
                "220307": "胶州市",
                "220308": "即墨市",
                "220309": "平度市",
                "220310": "莱西市",
                "220401": "淄川区",
                "220402": "张店区",
                "220403": "博山区",
                "220404": "临淄区",
                "220405": "周村区",
                "220406": "桓台县",
                "220407": "高青县",
                "220408": "沂源县",
                "220501": "德城区",
                "220502": "陵城区",
                "220503": "宁津县",
                "220504": "庆云县",
                "220505": "临邑县",
                "220506": "齐河县",
                "220507": "平原县",
                "220508": "夏津县",
                "220509": "武城县",
                "220510": "乐陵市",
                "220511": "禹城市",
                "220601": "芝罘区",
                "220602": "福山区",
                "220603": "莱山区",
                "220604": "牟平区",
                "220605": "长岛县",
                "220606": "龙口市",
                "220607": "莱阳市",
                "220608": "莱州市",
                "220609": "蓬莱市",
                "220610": "招远市",
                "220611": "栖霞市",
                "220612": "海阳市",
                "220613": "开发区",
                "220701": "潍城区",
                "220702": "寒亭区",
                "220703": "坊子区",
                "220704": "奎文区",
                "220705": "临朐县",
                "220706": "昌乐县",
                "220707": "青州市",
                "220708": "诸城市",
                "220709": "寿光市",
                "220710": "安丘市",
                "220711": "高密市",
                "220712": "昌邑市",
                "220801": "市中区",
                "220802": "任城区",
                "220803": "微山县",
                "220804": "鱼台县",
                "220805": "金乡县",
                "220806": "嘉祥县",
                "220807": "汶上县",
                "220808": "泗水县",
                "220809": "梁山县",
                "220810": "曲阜市",
                "220811": "兖州区",
                "220812": "邹城市",
                "220813": "高新区",
                "220901": "泰山区",
                "220902": "岱岳区",
                "220903": "宁阳县",
                "220904": "东平县",
                "220905": "新泰市",
                "220906": "肥城市",
                "221001": "兰山区",
                "221002": "罗庄区",
                "221003": "河东区",
                "221004": "沂南县",
                "221005": "郯城县",
                "221006": "沂水县",
                "221007": "兰陵县",
                "221008": "费县",
                "221009": "平邑县",
                "221010": "莒南县",
                "221011": "蒙阴县",
                "221012": "临沭县",
                "221101": "滨城区",
                "221102": "惠民县",
                "221103": "阳信县",
                "221104": "无棣县",
                "221105": "沾化区",
                "221106": "博兴县",
                "221107": "邹平县",
                "221201": "东营区",
                "221202": "河口区",
                "221203": "垦利县",
                "221204": "利津县",
                "221205": "广饶县",
                "221301": "环翠区",
                "221302": "文登区",
                "221303": "荣成市",
                "221304": "乳山市",
                "221305": "经济技术开发区",
                "221306": "高技术产业开发区",
                "221307": "工业新区",
                "221401": "市中区",
                "221402": "薛城区",
                "221403": "峄城区",
                "221404": "台儿庄区",
                "221405": "山亭区",
                "221406": "滕州市",
                "221501": "东港区",
                "221502": "岚山区",
                "221503": "五莲县",
                "221504": "莒县",
                "221601": "莱城区",
                "221602": "钢城区",
                "221701": "东昌府区",
                "221702": "阳谷县",
                "221703": "莘县",
                "221704": "茌平县",
                "221705": "东阿县",
                "221706": "冠县",
                "221707": "高唐县",
                "221708": "临清市",
                "230101": "柯城区",
                "230102": "衢江区",
                "230103": "常山县",
                "230104": "开化县",
                "230105": "龙游县",
                "230106": "江山市",
                "230201": "上城区",
                "230202": "下城区",
                "230203": "江干区",
                "230204": "拱墅区",
                "230205": "西湖区",
                "230206": "滨江区",
                "230207": "萧山区",
                "230208": "余杭区",
                "230209": "桐庐县",
                "230210": "淳安县",
                "230211": "建德市",
                "230212": "富阳区",
                "230213": "临安市",
                "230214": "下沙区",
                "230301": "吴兴区",
                "230302": "南浔区",
                "230303": "德清县",
                "230304": "长兴县",
                "230305": "安吉县",
                "230401": "南湖区",
                "230402": "秀洲区",
                "230403": "嘉善县",
                "230404": "海盐县",
                "230405": "海宁市",
                "230406": "平湖市",
                "230407": "桐乡市",
                "230501": "海曙区",
                "230502": "江东区",
                "230503": "江北区",
                "230504": "北仑区",
                "230505": "镇海区",
                "230506": "鄞州区",
                "230507": "象山县",
                "230508": "宁海县",
                "230509": "余姚市",
                "230510": "慈溪市",
                "230511": "奉化市",
                "230601": "越城区",
                "230602": "柯桥区",
                "230603": "新昌县",
                "230604": "诸暨市",
                "230605": "上虞区",
                "230606": "嵊州市",
                "230701": "椒江区",
                "230702": "黄岩区",
                "230703": "路桥区",
                "230704": "玉环县",
                "230705": "三门县",
                "230706": "天台县",
                "230707": "仙居县",
                "230708": "温岭市",
                "230709": "临海市",
                "230801": "鹿城区",
                "230802": "龙湾区",
                "230803": "瓯海区",
                "230804": "洞头县",
                "230805": "永嘉县",
                "230806": "平阳县",
                "230807": "苍南县",
                "230808": "文成县",
                "230809": "泰顺县",
                "230810": "瑞安市",
                "230811": "乐清市",
                "230901": "莲都区",
                "230902": "青田县",
                "230903": "缙云县",
                "230904": "遂昌县",
                "230905": "松阳县",
                "230906": "云和县",
                "230907": "庆元县",
                "230908": "景宁县",
                "230909": "龙泉市",
                "231001": "婺城区",
                "231002": "金东区",
                "231003": "武义县",
                "231004": "浦江县",
                "231005": "磐安县",
                "231006": "兰溪市",
                "231007": "义乌市",
                "231008": "东阳市",
                "231009": "永康市",
                "231101": "定海区",
                "231102": "普陀区",
                "231103": "岱山县",
                "231104": "嵊泗县",
                "240101": "月湖区",
                "240102": "余江县",
                "240103": "贵溪市",
                "240201": "渝水区",
                "240202": "分宜县",
                "240301": "东湖区",
                "240302": "西湖区",
                "240303": "青云谱区",
                "240304": "湾里区",
                "240305": "青山湖区",
                "240306": "南昌县",
                "240307": "新建县",
                "240308": "安义县",
                "240309": "进贤县",
                "240310": "高新开发区",
                "240311": "经济开发区",
                "240312": "英雄开发区",
                "240313": "红谷滩新区",
                "240401": "庐山区",
                "240402": "浔阳区",
                "240403": "九江县",
                "240404": "武宁县",
                "240405": "修水县",
                "240406": "永修县",
                "240407": "德安县",
                "240408": "星子县",
                "240409": "都昌县",
                "240410": "湖口县",
                "240411": "彭泽县",
                "240412": "瑞昌市",
                "240413": "共青城市",
                "240501": "信州区",
                "240502": "上饶县",
                "240503": "广丰县",
                "240504": "玉山县",
                "240505": "铅山县",
                "240506": "横峰县",
                "240507": "弋阳县",
                "240508": "余干县",
                "240509": "鄱阳县",
                "240510": "万年县",
                "240511": "婺源县",
                "240512": "德兴市",
                "240601": "临川区",
                "240602": "南城县",
                "240603": "黎川县",
                "240604": "南丰县",
                "240605": "崇仁县",
                "240606": "乐安县",
                "240607": "宜黄县",
                "240608": "金溪县",
                "240609": "资溪县",
                "240610": "东乡县",
                "240611": "广昌县",
                "240701": "袁州区",
                "240702": "奉新县",
                "240703": "万载县",
                "240704": "上高县",
                "240705": "宜丰县",
                "240706": "靖安县",
                "240707": "铜鼓县",
                "240708": "丰城市",
                "240709": "樟树市",
                "240710": "高安市",
                "240801": "吉州区",
                "240802": "青原区",
                "240803": "吉安县",
                "240804": "吉水县",
                "240805": "峡江县",
                "240806": "新干县",
                "240807": "永丰县",
                "240808": "泰和县",
                "240809": "遂川县",
                "240810": "万安县",
                "240811": "安福县",
                "240812": "永新县",
                "240813": "井冈山市",
                "240901": "章贡区",
                "240902": "赣县",
                "240903": "信丰县",
                "240904": "大余县",
                "240905": "上犹县",
                "240906": "崇义县",
                "240907": "安远县",
                "240908": "龙南县",
                "240909": "定南县",
                "240910": "全南县",
                "240911": "宁都县",
                "240912": "于都县",
                "240913": "兴国县",
                "240914": "会昌县",
                "240915": "寻乌县",
                "240916": "石城县",
                "240917": "瑞金市",
                "240918": "南康区",
                "241001": "昌江区",
                "241002": "珠山区",
                "241003": "浮梁县",
                "241004": "乐平市",
                "241101": "安源区",
                "241102": "湘东区",
                "241103": "莲花县",
                "241104": "上栗县",
                "241105": "芦溪县",
                "250101": "鼓楼区",
                "250102": "台江区",
                "250103": "仓山区",
                "250104": "马尾区",
                "250105": "晋安区",
                "250106": "闽侯县",
                "250107": "连江县",
                "250108": "罗源县",
                "250109": "闽清县",
                "250110": "永泰县",
                "250111": "平潭县",
                "250112": "福清市",
                "250113": "长乐市",
                "250201": "思明区",
                "250202": "海沧区",
                "250203": "湖里区",
                "250204": "集美区",
                "250205": "同安区",
                "250206": "翔安区",
                "250301": "蕉城区",
                "250302": "霞浦县",
                "250303": "古田县",
                "250304": "屏南县",
                "250305": "寿宁县",
                "250306": "周宁县",
                "250307": "柘荣县",
                "250308": "福安市",
                "250309": "福鼎市",
                "250401": "城厢区",
                "250402": "涵江区",
                "250403": "荔城区",
                "250404": "秀屿区",
                "250405": "仙游县",
                "250501": "鲤城区",
                "250502": "丰泽区",
                "250503": "洛江区",
                "250504": "泉港区",
                "250505": "惠安县",
                "250506": "安溪县",
                "250507": "永春县",
                "250508": "德化县",
                "250509": "石狮市",
                "250510": "晋江市",
                "250511": "南安市",
                "250601": "芗城区",
                "250602": "龙文区",
                "250603": "云霄县",
                "250604": "漳浦县",
                "250605": "诏安县",
                "250606": "长泰县",
                "250607": "东山县",
                "250608": "南靖县",
                "250609": "平和县",
                "250610": "华安县",
                "250611": "龙海市",
                "250701": "新罗区",
                "250702": "长汀县",
                "250703": "永定区",
                "250704": "上杭县",
                "250705": "武平县",
                "250706": "连城县",
                "250707": "漳平市",
                "250801": "梅列区",
                "250802": "三元区",
                "250803": "明溪县",
                "250804": "清流县",
                "250805": "宁化县",
                "250806": "大田县",
                "250807": "尤溪县",
                "250808": "沙县",
                "250809": "将乐县",
                "250810": "泰宁县",
                "250811": "建宁县",
                "250812": "永安市",
                "250901": "延平区",
                "250902": "顺昌县",
                "250903": "浦城县",
                "250904": "光泽县",
                "250905": "松溪县",
                "250906": "政和县",
                "250907": "邵武市",
                "250908": "武夷山市",
                "250909": "建瓯市",
                "250910": "建阳区",
                "260101": "岳阳楼区",
                "260102": "云溪区",
                "260103": "君山区",
                "260104": "岳阳县",
                "260105": "华容县",
                "260106": "湘阴县",
                "260107": "平江县",
                "260108": "汨罗市",
                "260109": "临湘市",
                "260201": "芙蓉区",
                "260202": "天心区",
                "260203": "岳麓区",
                "260204": "开福区",
                "260205": "雨花区",
                "260206": "长沙县",
                "260207": "望城区",
                "260208": "宁乡县",
                "260209": "浏阳市",
                "260301": "雨湖区",
                "260302": "岳塘区",
                "260303": "湘潭县",
                "260304": "湘乡市",
                "260305": "韶山市",
                "260401": "荷塘区",
                "260402": "芦淞区",
                "260403": "石峰区",
                "260404": "天元区",
                "260405": "株洲县",
                "260406": "攸县",
                "260407": "茶陵县",
                "260408": "炎陵县",
                "260409": "醴陵市",
                "260501": "珠晖区",
                "260502": "雁峰区",
                "260503": "石鼓区",
                "260504": "蒸湘区",
                "260505": "南岳区",
                "260506": "衡阳县",
                "260507": "衡南县",
                "260508": "衡山县",
                "260509": "衡东县",
                "260510": "祁东县",
                "260511": "耒阳市",
                "260512": "常宁市",
                "260601": "北湖区",
                "260602": "苏仙区",
                "260603": "桂阳县",
                "260604": "宜章县",
                "260605": "永兴县",
                "260606": "嘉禾县",
                "260607": "临武县",
                "260608": "汝城县",
                "260609": "桂东县",
                "260610": "安仁县",
                "260611": "资兴市",
                "260701": "武陵区",
                "260702": "鼎城区",
                "260703": "安乡县",
                "260704": "汉寿县",
                "260705": "澧县",
                "260706": "临澧县",
                "260707": "桃源县",
                "260708": "石门县",
                "260709": "津市市",
                "260801": "资阳区",
                "260802": "赫山区",
                "260803": "南县",
                "260804": "桃江县",
                "260805": "安化县",
                "260806": "沅江市",
                "260901": "娄星区",
                "260902": "双峰县",
                "260903": "新化县",
                "260904": "冷水江市",
                "260905": "涟源市",
                "261001": "双清区",
                "261002": "大祥区",
                "261003": "北塔区",
                "261004": "邵东县",
                "261005": "新邵县",
                "261006": "邵阳县",
                "261007": "隆回县",
                "261008": "洞口县",
                "261009": "绥宁县",
                "261010": "新宁县",
                "261011": "城步县",
                "261012": "武冈市",
                "261101": "吉首市",
                "261102": "花垣县",
                "261103": "凤凰县",
                "261104": "泸溪县",
                "261105": "保靖县",
                "261106": "古丈县",
                "261107": "永顺县",
                "261108": "龙山县",
                "261201": "永定区",
                "261202": "武陵源区",
                "261203": "慈利县",
                "261204": "桑植县",
                "261301": "鹤城区",
                "261302": "中方县",
                "261303": "沅陵县",
                "261304": "辰溪县",
                "261305": "溆浦县",
                "261306": "会同县",
                "261307": "麻阳县",
                "261308": "新晃县",
                "261309": "芷江县",
                "261310": "靖州县",
                "261311": "通道县",
                "261312": "洪江市",
                "261401": "零陵区",
                "261402": "冷水滩区",
                "261403": "祁阳县",
                "261404": "东安县",
                "261405": "双牌县",
                "261406": "道县",
                "261407": "江永县",
                "261408": "宁远县",
                "261409": "蓝山县",
                "261410": "新田县",
                "261411": "江华县",
                "270101": "江岸区",
                "270102": "江汉区",
                "270103": "硚口区",
                "270104": "汉阳区",
                "270105": "武昌区",
                "270106": "青山区",
                "270107": "洪山区",
                "270108": "东西湖区",
                "270109": "汉南区",
                "270110": "蔡甸区",
                "270111": "江夏区",
                "270112": "黄陂区",
                "270113": "新洲区",
                "270201": "襄城区",
                "270202": "樊城区",
                "270203": "襄州区",
                "270204": "南漳县",
                "270205": "谷城县",
                "270206": "保康县",
                "270207": "老河口市",
                "270208": "枣阳市",
                "270209": "宜城市",
                "270301": "梁子湖区",
                "270302": "华容区",
                "270303": "鄂城区",
                "270401": "孝南区",
                "270402": "孝昌县",
                "270403": "大悟县",
                "270404": "云梦县",
                "270405": "应城市",
                "270406": "安陆市",
                "270407": "汉川市",
                "270501": "黄州区",
                "270502": "团风县",
                "270503": "红安县",
                "270504": "罗田县",
                "270505": "英山县",
                "270506": "浠水县",
                "270507": "蕲春县",
                "270508": "黄梅县",
                "270509": "麻城市",
                "270510": "武穴市",
                "270601": "黄石港区",
                "270602": "西塞山区",
                "270603": "下陆区",
                "270604": "铁山区",
                "270605": "阳新县",
                "270606": "大冶市",
                "270701": "咸安区",
                "270702": "嘉鱼县",
                "270703": "通城县",
                "270704": "崇阳县",
                "270705": "通山县",
                "270706": "赤壁市",
                "270801": "沙市区",
                "270802": "荆州区",
                "270803": "公安县",
                "270804": "监利县",
                "270805": "江陵县",
                "270806": "石首市",
                "270807": "洪湖市",
                "270808": "松滋市",
                "270901": "西陵区",
                "270902": "伍家岗区",
                "270903": "点军区",
                "270904": "猇亭区",
                "270905": "夷陵区",
                "270906": "远安县",
                "270907": "兴山县",
                "270908": "秭归县",
                "270909": "长阳县",
                "270910": "五峰县",
                "270911": "宜都市",
                "270912": "当阳市",
                "270913": "枝江市",
                "271001": "恩施市",
                "271002": "利川市",
                "271003": "建始县",
                "271004": "巴东县",
                "271005": "宣恩县",
                "271006": "咸丰县",
                "271007": "来凤县",
                "271008": "鹤峰县",
                "271101": "茅箭区",
                "271102": "张湾区",
                "271103": "郧阳区",
                "271104": "郧西县",
                "271105": "竹山县",
                "271106": "竹溪县",
                "271107": "房县",
                "271108": "丹江口市",
                "271201": "林区",
                "271301": "曾都区",
                "271302": "广水市",
                "271303": "随县",
                "271401": "东宝区",
                "271402": "掇刀区",
                "271403": "京山县",
                "271404": "沙洋县",
                "271405": "钟祥市",
                "271501": "仙桃市",
                "271502": "潜江市",
                "271503": "天门市",
                "280101": "梁园区",
                "280102": "睢阳区",
                "280103": "民权县",
                "280104": "睢县",
                "280105": "宁陵县",
                "280106": "柘城县",
                "280107": "虞城县",
                "280108": "夏邑县",
                "280109": "永城市",
                "280201": "中原区",
                "280202": "二七区",
                "280203": "管城回族区",
                "280204": "金水区",
                "280205": "惠济区",
                "280206": "荥阳市",
                "280207": "巩义市",
                "280208": "登封市",
                "280209": "新密市",
                "280210": "新郑市",
                "280211": "中牟县",
                "280212": "上街区",
                "280213": "郑东新区",
                "280214": "高新区",
                "280301": "文峰区",
                "280302": "北关区",
                "280303": "殷都区",
                "280304": "龙安区",
                "280305": "安阳县",
                "280306": "汤阴县",
                "280307": "滑县",
                "280308": "内黄县",
                "280309": "林州市",
                "280401": "红旗区",
                "280402": "卫滨区",
                "280403": "凤泉区",
                "280404": "牧野区",
                "280405": "新乡县",
                "280406": "获嘉县",
                "280407": "原阳县",
                "280408": "延津县",
                "280409": "封丘县",
                "280410": "长垣县",
                "280411": "卫辉市",
                "280412": "辉县市",
                "280501": "魏都区",
                "280502": "许昌县",
                "280503": "鄢陵县",
                "280504": "襄城县",
                "280505": "禹州市",
                "280506": "长葛市",
                "280601": "新华区",
                "280602": "卫东区",
                "280603": "石龙区",
                "280604": "湛河区",
                "280605": "宝丰县",
                "280606": "叶县",
                "280607": "鲁山县",
                "280608": "郏县",
                "280609": "舞钢市",
                "280610": "汝州市",
                "280611": "新城区",
                "280701": "浉河区",
                "280702": "平桥区",
                "280703": "罗山县",
                "280704": "光山县",
                "280705": "新县",
                "280706": "商城县",
                "280707": "固始县",
                "280708": "潢川县",
                "280709": "淮滨县",
                "280710": "息县",
                "280801": "宛城区",
                "280802": "卧龙区",
                "280803": "南召县",
                "280804": "方城县",
                "280805": "西峡县",
                "280806": "镇平县",
                "280807": "内乡县",
                "280808": "淅川县",
                "280809": "社旗县",
                "280810": "唐河县",
                "280811": "新野县",
                "280812": "邓州市",
                "280813": "桐柏县",
                "280901": "龙亭区",
                "280902": "顺河回族区",
                "280903": "鼓楼区",
                "280904": "禹王台区",
                "280905": "金明区",
                "280906": "杞县",
                "280907": "通许县",
                "280908": "尉氏县",
                "280909": "祥符区",
                "280910": "兰考县",
                "281001": "老城区",
                "281002": "西工区",
                "281003": "瀍河回族区",
                "281004": "涧西区",
                "281005": "吉利区",
                "281006": "洛龙区",
                "281007": "孟津县",
                "281008": "新安县",
                "281009": "栾川县",
                "281010": "嵩县",
                "281011": "汝阳县",
                "281012": "宜阳县",
                "281013": "洛宁县",
                "281014": "伊川县",
                "281015": "偃师市",
                "281101": "解放区",
                "281102": "中站区",
                "281103": "马村区",
                "281104": "山阳区",
                "281105": "修武县",
                "281106": "博爱县",
                "281107": "武陟县",
                "281108": "温县",
                "281109": "沁阳市",
                "281110": "孟州市",
                "281111": "高新区",
                "281201": "鹤山区",
                "281202": "山城区",
                "281203": "淇滨区",
                "281204": "浚县",
                "281205": "淇县",
                "281301": "华龙区",
                "281302": "清丰县",
                "281303": "南乐县",
                "281304": "范县",
                "281305": "台前县",
                "281306": "濮阳县",
                "281401": "川汇区",
                "281402": "扶沟县",
                "281403": "西华县",
                "281404": "商水县",
                "281405": "沈丘县",
                "281406": "郸城县",
                "281407": "淮阳县",
                "281408": "太康县",
                "281409": "鹿邑县",
                "281410": "项城市",
                "281501": "源汇区",
                "281502": "郾城区",
                "281503": "召陵区",
                "281504": "舞阳县",
                "281505": "临颍县",
                "281601": "驿城区",
                "281602": "西平县",
                "281603": "上蔡县",
                "281604": "平舆县",
                "281605": "正阳县",
                "281606": "确山县",
                "281607": "泌阳县",
                "281608": "汝南县",
                "281609": "遂平县",
                "281610": "新蔡县",
                "281701": "湖滨区",
                "281702": "渑池县",
                "281703": "陕县",
                "281704": "卢氏县",
                "281705": "义马市",
                "281706": "灵宝市",
                "281801": "济源县",
                "290101": "荔湾区",
                "290102": "越秀区",
                "290103": "海珠区",
                "290104": "天河区",
                "290105": "白云区",
                "290106": "黄埔区",
                "290107": "番禺区",
                "290108": "花都区",
                "290109": "南沙区",
                "290110": "萝岗区",
                "290111": "增城区",
                "290112": "从化区",
                "290201": "城区",
                "290202": "海丰县",
                "290203": "陆河县",
                "290204": "陆丰市",
                "290301": "江城区",
                "290302": "岗侨区",
                "290303": "高新区",
                "290304": "阳西县",
                "290305": "阳东区",
                "290306": "阳春市",
                "290401": "榕城区",
                "290402": "揭东区",
                "290403": "揭西县",
                "290404": "惠来县",
                "290405": "普宁市",
                "290406": "东山区",
                "290501": "茂南区",
                "290502": "电白区",
                "290503": "电白县",
                "290504": "高州市",
                "290505": "化州市",
                "290506": "信宜市",
                "290601": "蓬江区",
                "290602": "江海区",
                "290603": "新会区",
                "290604": "台山市",
                "290605": "开平市",
                "290606": "鹤山市",
                "290607": "恩平市",
                "290701": "武江区",
                "290702": "浈江区",
                "290703": "曲江区",
                "290704": "始兴县",
                "290705": "仁化县",
                "290706": "翁源县",
                "290707": "乳源县",
                "290708": "新丰县",
                "290709": "乐昌市",
                "290710": "南雄市",
                "290801": "惠城区",
                "290802": "惠阳区",
                "290803": "博罗县",
                "290804": "惠东县",
                "290805": "龙门县",
                "290901": "梅江区",
                "290902": "梅县区",
                "290903": "大埔县",
                "290904": "丰顺县",
                "290905": "五华县",
                "290906": "平远县",
                "290907": "蕉岭县",
                "290908": "兴宁市",
                "291001": "龙湖区",
                "291002": "金平区",
                "291003": "濠江区",
                "291004": "潮阳区",
                "291005": "潮南区",
                "291006": "澄海区",
                "291007": "南澳县",
                "291101": "罗湖区",
                "291102": "福田区",
                "291103": "南山区",
                "291104": "宝安区",
                "291105": "龙岗区",
                "291106": "盐田区",
                "291107": "龙华新区",
                "291108": "光明新区",
                "291109": "坪山新区",
                "291110": "大鹏新区",
                "291201": "香洲区",
                "291202": "斗门区",
                "291203": "金湾区",
                "291301": "禅城区",
                "291302": "南海区",
                "291303": "顺德区",
                "291304": "三水区",
                "291305": "高明区",
                "291401": "端州区",
                "291402": "鼎湖区",
                "291403": "广宁县",
                "291404": "怀集县",
                "291405": "封开县",
                "291406": "德庆县",
                "291407": "高要市",
                "291408": "四会市",
                "291501": "赤坎区",
                "291502": "霞山区",
                "291503": "坡头区",
                "291504": "麻章区",
                "291505": "遂溪县",
                "291506": "徐闻县",
                "291507": "廉江市",
                "291508": "雷州市",
                "291509": "吴川市",
                "291510": "经济技术开发区",
                "291601": "中山市",
                "291701": "源城区",
                "291702": "紫金县",
                "291703": "龙川县",
                "291704": "连平县",
                "291705": "和平县",
                "291706": "东源县",
                "291801": "清城区",
                "291802": "佛冈县",
                "291803": "阳山县",
                "291804": "连山县",
                "291805": "连南县",
                "291806": "清新县",
                "291807": "英德市",
                "291808": "连州市",
                "291901": "云城区",
                "291902": "新兴县",
                "291903": "郁南县",
                "291904": "云安区",
                "291905": "罗定市",
                "292001": "湘桥区",
                "292002": "潮安区",
                "292003": "饶平县",
                "292004": "枫溪区",
                "292101": "东莞市",
                "300101": "西沙群岛",
                "300102": "南沙群岛",
                "300103": "中沙群岛",
                "300201": "东方市",
                "300301": "儋州市",
                "300401": "五指山市",
                "300501": "万宁市",
                "300601": "文昌市",
                "300701": "琼海市",
                "300801": "三亚市",
                "300901": "秀英区",
                "300902": "美兰区",
                "300903": "龙华区",
                "300904": "琼山区",
                "301001": "乐东县",
                "301002": "陵水县",
                "301003": "保亭县",
                "301004": "琼中县",
                "301005": "定安县",
                "301006": "屯昌县",
                "301007": "澄迈县",
                "301008": "临高县",
                "301009": "白沙县",
                "301010": "昌江县",
                "301011": "西沙群岛",
                "301012": "南沙群岛",
                "301013": "中沙群岛",
                "310101": "港口区",
                "310102": "防城区",
                "310103": "上思县",
                "310104": "东兴市",
                "310201": "兴宁区",
                "310202": "青秀区",
                "310203": "江南区",
                "310204": "西乡塘区",
                "310205": "良庆区",
                "310206": "邕宁区",
                "310207": "武鸣县",
                "310208": "隆安县",
                "310209": "马山县",
                "310210": "上林县",
                "310211": "宾阳县",
                "310212": "横县",
                "310301": "城中区",
                "310302": "鱼峰区",
                "310303": "柳南区",
                "310304": "柳北区",
                "310305": "柳江县",
                "310306": "柳城县",
                "310307": "鹿寨县",
                "310308": "融安县",
                "310309": "融水县",
                "310310": "三江县",
                "310401": "秀峰区",
                "310402": "叠彩区",
                "310403": "象山区",
                "310404": "七星区",
                "310405": "雁山区",
                "310406": "阳朔县",
                "310407": "临桂区",
                "310408": "灵川县",
                "310409": "全州县",
                "310410": "兴安县",
                "310411": "永福县",
                "310412": "灌阳县",
                "310413": "龙胜县",
                "310414": "资源县",
                "310415": "平乐县",
                "310416": "荔浦县",
                "310417": "恭城县",
                "310501": "万秀区",
                "310502": "长洲区",
                "310503": "苍梧县",
                "310504": "藤县",
                "310505": "蒙山县",
                "310506": "岑溪市",
                "310601": "港北区",
                "310602": "港南区",
                "310603": "覃塘区",
                "310604": "平南县",
                "310605": "桂平市",
                "310701": "右江区",
                "310702": "田阳县",
                "310703": "田东县",
                "310704": "平果县",
                "310705": "德保县",
                "310706": "靖西县",
                "310707": "那坡县",
                "310708": "凌云县",
                "310709": "乐业县",
                "310710": "田林县",
                "310711": "西林县",
                "310712": "隆林县",
                "310801": "钦南区",
                "310802": "钦北区",
                "310803": "灵山县",
                "310804": "浦北县",
                "310901": "金城江区",
                "310902": "南丹县",
                "310903": "天峨县",
                "310904": "凤山县",
                "310905": "东兰县",
                "310906": "罗城县",
                "310907": "环江县",
                "310908": "巴马县",
                "310909": "都安县",
                "310910": "大化县",
                "310911": "宜州市",
                "311001": "海城区",
                "311002": "银海区",
                "311003": "铁山港区",
                "311004": "合浦县",
                "311101": "江州区",
                "311102": "扶绥县",
                "311103": "宁明县",
                "311104": "龙州县",
                "311105": "大新县",
                "311106": "天等县",
                "311107": "凭祥市",
                "311201": "兴宾区",
                "311202": "忻城县",
                "311203": "象州县",
                "311204": "武宣县",
                "311205": "金秀县",
                "311206": "合山市",
                "311301": "八步区",
                "311302": "昭平县",
                "311303": "钟山县",
                "311304": "富川县",
                "311305": "平桂管理区",
                "311401": "玉州区",
                "311402": "容县",
                "311403": "陆川县",
                "311404": "博白县",
                "311405": "兴业县",
                "311406": "北流市",
                "320101": "南明区",
                "320102": "云岩区",
                "320103": "花溪区",
                "320104": "乌当区",
                "320105": "白云区",
                "320106": "小河区",
                "320107": "开阳县",
                "320108": "息烽县",
                "320109": "修文县",
                "320110": "清镇市",
                "320111": "金阳新区",
                "320112": "观山湖区",
                "320201": "红花岗区",
                "320202": "汇川区",
                "320203": "遵义县",
                "320204": "桐梓县",
                "320205": "绥阳县",
                "320206": "正安县",
                "320207": "道真县",
                "320208": "务川县",
                "320209": "凤冈县",
                "320210": "湄潭县",
                "320211": "余庆县",
                "320212": "习水县",
                "320213": "赤水市",
                "320214": "仁怀市",
                "320301": "西秀区",
                "320302": "平坝区",
                "320303": "普定县",
                "320304": "镇宁县",
                "320305": "关岭县",
                "320306": "紫云县",
                "320401": "都匀市",
                "320402": "福泉市",
                "320403": "荔波县",
                "320404": "贵定县",
                "320405": "瓮安县",
                "320406": "独山县",
                "320407": "平塘县",
                "320408": "罗甸县",
                "320409": "长顺县",
                "320410": "龙里县",
                "320411": "惠水县",
                "320412": "三都县",
                "320501": "凯里市",
                "320502": "黄平县",
                "320503": "施秉县",
                "320504": "三穗县",
                "320505": "镇远县",
                "320506": "岑巩县",
                "320507": "天柱县",
                "320508": "锦屏县",
                "320509": "剑河县",
                "320510": "台江县",
                "320511": "黎平县",
                "320512": "榕江县",
                "320513": "从江县",
                "320514": "雷山县",
                "320515": "麻江县",
                "320516": "丹寨县",
                "320601": "碧江区",
                "320602": "江口县",
                "320603": "玉屏县",
                "320604": "石阡县",
                "320605": "思南县",
                "320606": "印江县",
                "320607": "德江县",
                "320608": "沿河县",
                "320609": "松桃县",
                "320610": "万山区",
                "320701": "七星关区",
                "320702": "大方县",
                "320703": "黔西县",
                "320704": "金沙县",
                "320705": "织金县",
                "320706": "纳雍县",
                "320707": "威宁县",
                "320708": "赫章县",
                "320801": "钟山区",
                "320802": "六枝特区",
                "320803": "水城县",
                "320804": "盘县",
                "320901": "兴义市",
                "320902": "兴仁县",
                "320903": "普安县",
                "320904": "晴隆县",
                "320905": "贞丰县",
                "320906": "望谟县",
                "320907": "册亨县",
                "320908": "安龙县",
                "330101": "锦江区",
                "330102": "青羊区",
                "330103": "金牛区",
                "330104": "武侯区",
                "330105": "成华区",
                "330106": "龙泉驿区",
                "330107": "青白江区",
                "330108": "新都区",
                "330109": "温江区",
                "330110": "金堂县",
                "330111": "双流县",
                "330112": "郫县",
                "330113": "大邑县",
                "330114": "蒲江县",
                "330115": "新津县",
                "330116": "都江堰市",
                "330117": "彭州市",
                "330118": "邛崃市",
                "330119": "崇州市",
                "330120": "高新区",
                "330201": "东区",
                "330202": "西区",
                "330203": "仁和区",
                "330204": "米易县",
                "330205": "盐边县",
                "330301": "自流井区",
                "330302": "贡井区",
                "330303": "大安区",
                "330304": "沿滩区",
                "330305": "荣县",
                "330306": "富顺县",
                "330401": "涪城区",
                "330402": "游仙区",
                "330403": "三台县",
                "330404": "盐亭县",
                "330405": "安县",
                "330406": "梓潼县",
                "330407": "北川县",
                "330408": "平武县",
                "330409": "江油市",
                "330410": "高新区",
                "330501": "顺庆区",
                "330502": "高坪区",
                "330503": "嘉陵区",
                "330504": "南部县",
                "330505": "营山县",
                "330506": "蓬安县",
                "330507": "仪陇县",
                "330508": "西充县",
                "330509": "阆中市",
                "330601": "通川区",
                "330602": "达川区",
                "330603": "宣汉县",
                "330604": "开江县",
                "330605": "大竹县",
                "330606": "渠县",
                "330607": "万源市",
                "330701": "船山区",
                "330702": "安居区",
                "330703": "蓬溪县",
                "330704": "射洪县",
                "330705": "大英县",
                "330801": "广安区",
                "330802": "岳池县",
                "330803": "武胜县",
                "330804": "邻水县",
                "330805": "华蓥市",
                "330901": "巴州区",
                "330902": "通江县",
                "330903": "南江县",
                "330904": "平昌县",
                "330905": "恩阳区",
                "331001": "江阳区",
                "331002": "纳溪区",
                "331003": "龙马潭区",
                "331004": "泸县",
                "331005": "合江县",
                "331006": "叙永县",
                "331007": "古蔺县",
                "331101": "翠屏区",
                "331102": "宜宾县",
                "331103": "南溪区",
                "331104": "江安县",
                "331105": "长宁县",
                "331106": "高县",
                "331107": "珙县",
                "331108": "筠连县",
                "331109": "兴文县",
                "331110": "屏山县",
                "331201": "市中区",
                "331202": "东兴区",
                "331203": "威远县",
                "331204": "资中县",
                "331205": "隆昌县",
                "331301": "东坡区",
                "331302": "仁寿县",
                "331303": "彭山区",
                "331304": "洪雅县",
                "331305": "丹棱县",
                "331306": "青神县",
                "331401": "西昌市",
                "331402": "木里县",
                "331403": "盐源县",
                "331404": "德昌县",
                "331405": "会理县",
                "331406": "会东县",
                "331407": "宁南县",
                "331408": "普格县",
                "331409": "布拖县",
                "331410": "金阳县",
                "331411": "昭觉县",
                "331412": "喜德县",
                "331413": "冕宁县",
                "331414": "越西县",
                "331415": "甘洛县",
                "331416": "美姑县",
                "331417": "雷波县",
                "331501": "雨城区",
                "331502": "名山区",
                "331503": "荥经县",
                "331504": "汉源县",
                "331505": "石棉县",
                "331506": "天全县",
                "331507": "芦山县",
                "331508": "宝兴县",
                "331601": "康定县",
                "331602": "泸定县",
                "331603": "丹巴县",
                "331604": "九龙县",
                "331605": "雅江县",
                "331606": "道孚县",
                "331607": "炉霍县",
                "331608": "甘孜县",
                "331609": "新龙县",
                "331610": "德格县",
                "331611": "白玉县",
                "331612": "石渠县",
                "331613": "色达县",
                "331614": "理塘县",
                "331615": "巴塘县",
                "331616": "乡城县",
                "331617": "稻城县",
                "331618": "得荣县",
                "331701": "汶川县",
                "331702": "理县",
                "331703": "茂县",
                "331704": "松潘县",
                "331705": "九寨沟县",
                "331706": "金川县",
                "331707": "小金县",
                "331708": "黑水县",
                "331709": "马尔康县",
                "331710": "壤塘县",
                "331711": "阿坝县",
                "331712": "若尔盖县",
                "331713": "红原县",
                "331801": "旌阳区",
                "331802": "中江县",
                "331803": "罗江县",
                "331804": "广汉市",
                "331805": "什邡市",
                "331806": "绵竹市",
                "331901": "利州区",
                "331902": "昭化区",
                "331903": "朝天区",
                "331904": "旺苍县",
                "331905": "青川县",
                "331906": "剑阁县",
                "331907": "苍溪县",
                "332001": "雁江区",
                "332002": "安岳县",
                "332003": "乐至县",
                "332004": "简阳市",
                "332101": "市中区",
                "332102": "沙湾区",
                "332103": "五通桥区",
                "332104": "金口河区",
                "332105": "犍为县",
                "332106": "井研县",
                "332107": "夹江县",
                "332108": "沐川县",
                "332109": "峨边县",
                "332110": "马边县",
                "332111": "峨眉山市",
                "340101": "景洪市",
                "340102": "勐海县",
                "340103": "勐腊县",
                "340201": "瑞丽市",
                "340202": "芒市",
                "340203": "梁河县",
                "340204": "盈江县",
                "340205": "陇川县",
                "340301": "昭阳区",
                "340302": "鲁甸县",
                "340303": "巧家县",
                "340304": "盐津县",
                "340305": "大关县",
                "340306": "永善县",
                "340307": "绥江县",
                "340308": "镇雄县",
                "340309": "彝良县",
                "340310": "威信县",
                "340311": "水富县",
                "340401": "五华区",
                "340402": "盘龙区",
                "340403": "官渡区",
                "340404": "西山区",
                "340405": "东川区",
                "340406": "呈贡区",
                "340407": "晋宁县",
                "340408": "富民县",
                "340409": "宜良县",
                "340410": "石林县",
                "340411": "嵩明县",
                "340412": "禄劝县",
                "340413": "寻甸县",
                "340414": "安宁市",
                "340501": "大理市",
                "340502": "漾濞县",
                "340503": "祥云县",
                "340504": "宾川县",
                "340505": "弥渡县",
                "340506": "南涧县",
                "340507": "巍山县",
                "340508": "永平县",
                "340509": "云龙县",
                "340510": "洱源县",
                "340511": "剑川县",
                "340512": "鹤庆县",
                "340601": "个旧市",
                "340602": "开远市",
                "340603": "蒙自市",
                "340604": "屏边县",
                "340605": "建水县",
                "340606": "石屏县",
                "340607": "弥勒市",
                "340608": "泸西县",
                "340609": "元阳县",
                "340610": "红河县",
                "340611": "金平县",
                "340612": "绿春县",
                "340613": "河口县",
                "340701": "麒麟区",
                "340702": "马龙县",
                "340703": "陆良县",
                "340704": "师宗县",
                "340705": "罗平县",
                "340706": "富源县",
                "340707": "会泽县",
                "340708": "沾益县",
                "340709": "宣威市",
                "340801": "隆阳区",
                "340802": "施甸县",
                "340803": "腾冲县",
                "340804": "龙陵县",
                "340805": "昌宁县",
                "340901": "文山市",
                "340902": "砚山县",
                "340903": "西畴县",
                "340904": "麻栗坡县",
                "340905": "马关县",
                "340906": "丘北县",
                "340907": "广南县",
                "340908": "富宁县",
                "341001": "红塔区",
                "341002": "江川县",
                "341003": "澄江县",
                "341004": "通海县",
                "341005": "华宁县",
                "341006": "易门县",
                "341007": "峨山县",
                "341008": "新平县",
                "341009": "元江县",
                "341101": "楚雄市",
                "341102": "双柏县",
                "341103": "牟定县",
                "341104": "南华县",
                "341105": "姚安县",
                "341106": "大姚县",
                "341107": "永仁县",
                "341108": "元谋县",
                "341109": "武定县",
                "341110": "禄丰县",
                "341201": "思茅区",
                "341202": "宁洱县",
                "341203": "墨江县",
                "341204": "景东县",
                "341205": "景谷县",
                "341206": "镇沅县",
                "341207": "江城县",
                "341208": "孟连县",
                "341209": "澜沧县",
                "341210": "西盟县",
                "341301": "临翔区",
                "341302": "凤庆县",
                "341303": "云县",
                "341304": "永德县",
                "341305": "镇康县",
                "341306": "双江县",
                "341307": "耿马县",
                "341308": "沧源县",
                "341401": "泸水县",
                "341402": "福贡县",
                "341403": "贡山县",
                "341404": "兰坪县",
                "341501": "香格里拉市",
                "341502": "德钦县",
                "341503": "维西县",
                "341601": "古城区",
                "341602": "玉龙县",
                "341603": "永胜县",
                "341604": "华坪县",
                "341605": "宁蒗县",
                "350101": "新城区",
                "350102": "碑林区",
                "350103": "莲湖区",
                "350104": "灞桥区",
                "350105": "未央区",
                "350106": "雁塔区",
                "350107": "阎良区",
                "350108": "临潼区",
                "350109": "长安区",
                "350110": "蓝田县",
                "350111": "周至县",
                "350112": "户县",
                "350113": "高陵区",
                "350201": "秦都区",
                "350202": "杨陵区",
                "350203": "渭城区",
                "350204": "三原县",
                "350205": "泾阳县",
                "350206": "乾县",
                "350207": "礼泉县",
                "350208": "永寿县",
                "350209": "彬县",
                "350210": "长武县",
                "350211": "旬邑县",
                "350212": "淳化县",
                "350213": "武功县",
                "350214": "兴平市",
                "350301": "宝塔区",
                "350302": "延长县",
                "350303": "延川县",
                "350304": "子长县",
                "350305": "安塞县",
                "350306": "志丹县",
                "350307": "吴起县",
                "350308": "甘泉县",
                "350309": "富县",
                "350310": "洛川县",
                "350311": "宜川县",
                "350312": "黄龙县",
                "350313": "黄陵县",
                "350401": "榆阳区",
                "350402": "神木县",
                "350403": "府谷县",
                "350404": "横山县",
                "350405": "靖边县",
                "350406": "定边县",
                "350407": "绥德县",
                "350408": "米脂县",
                "350409": "佳县",
                "350410": "吴堡县",
                "350411": "清涧县",
                "350412": "子洲县",
                "350501": "临渭区",
                "350502": "华县",
                "350503": "潼关县",
                "350504": "大荔县",
                "350505": "合阳县",
                "350506": "澄城县",
                "350507": "蒲城县",
                "350508": "白水县",
                "350509": "富平县",
                "350510": "韩城市",
                "350511": "华阴市",
                "350601": "商州区",
                "350602": "洛南县",
                "350603": "丹凤县",
                "350604": "商南县",
                "350605": "山阳县",
                "350606": "镇安县",
                "350607": "柞水县",
                "350701": "汉滨区",
                "350702": "汉阴县",
                "350703": "石泉县",
                "350704": "宁陕县",
                "350705": "紫阳县",
                "350706": "岚皋县",
                "350707": "平利县",
                "350708": "镇坪县",
                "350709": "旬阳县",
                "350710": "白河县",
                "350801": "汉台区",
                "350802": "南郑县",
                "350803": "城固县",
                "350804": "洋县",
                "350805": "西乡县",
                "350806": "勉县",
                "350807": "宁强县",
                "350808": "略阳县",
                "350809": "镇巴县",
                "350810": "留坝县",
                "350811": "佛坪县",
                "350901": "渭滨区",
                "350902": "金台区",
                "350903": "陈仓区",
                "350904": "凤翔县",
                "350905": "岐山县",
                "350906": "扶风县",
                "350907": "眉县",
                "350908": "陇县",
                "350909": "千阳县",
                "350910": "麟游县",
                "350911": "凤县",
                "350912": "太白县",
                "351001": "王益区",
                "351002": "印台区",
                "351003": "耀州区",
                "351004": "宜君县",
                "360101": "临夏市",
                "360102": "临夏县",
                "360103": "康乐县",
                "360104": "永靖县",
                "360105": "广河县",
                "360106": "和政县",
                "360107": "东乡县",
                "360108": "积石山县",
                "360201": "城关区",
                "360202": "七里河区",
                "360203": "西固区",
                "360204": "安宁区",
                "360205": "红古区",
                "360206": "永登县",
                "360207": "皋兰县",
                "360208": "榆中县",
                "360301": "安定区",
                "360302": "通渭县",
                "360303": "陇西县",
                "360304": "渭源县",
                "360305": "临洮县",
                "360306": "漳县",
                "360307": "岷县",
                "360401": "崆峒区",
                "360402": "泾川县",
                "360403": "灵台县",
                "360404": "崇信县",
                "360405": "华亭县",
                "360406": "庄浪县",
                "360407": "静宁县",
                "360501": "西峰区",
                "360502": "庆城县",
                "360503": "环县",
                "360504": "华池县",
                "360505": "合水县",
                "360506": "正宁县",
                "360507": "宁县",
                "360508": "镇原县",
                "360601": "凉州区",
                "360602": "民勤县",
                "360603": "古浪县",
                "360604": "天祝县",
                "360701": "甘州区",
                "360702": "肃南县",
                "360703": "民乐县",
                "360704": "临泽县",
                "360705": "高台县",
                "360706": "山丹县",
                "360801": "肃州区",
                "360802": "金塔县",
                "360803": "瓜州县",
                "360804": "肃北县",
                "360805": "阿克塞县",
                "360806": "玉门市",
                "360807": "敦煌市",
                "360901": "秦州区",
                "360902": "麦积区",
                "360903": "清水县",
                "360904": "秦安县",
                "360905": "甘谷县",
                "360906": "武山县",
                "360907": "张家川县",
                "361001": "武都区",
                "361002": "成县",
                "361003": "文县",
                "361004": "宕昌县",
                "361005": "康县",
                "361006": "西和县",
                "361007": "礼县",
                "361008": "徽县",
                "361009": "两当县",
                "361101": "金川区",
                "361102": "永昌县",
                "361201": "合作市",
                "361202": "临潭县",
                "361203": "卓尼县",
                "361204": "舟曲县",
                "361205": "迭部县",
                "361206": "玛曲县",
                "361207": "碌曲县",
                "361208": "夏河县",
                "361301": "嘉峪关市",
                "361302": "雄关区",
                "361303": "长城区",
                "361304": "镜铁区",
                "361401": "白银区",
                "361402": "平川区",
                "361403": "靖远县",
                "361404": "会宁县",
                "361405": "景泰县",
                "370101": "兴庆区",
                "370102": "西夏区",
                "370103": "金凤区",
                "370104": "永宁县",
                "370105": "贺兰县",
                "370106": "灵武市",
                "370201": "大武口区",
                "370202": "惠农区",
                "370203": "平罗县",
                "370301": "利通区",
                "370302": "盐池县",
                "370303": "同心县",
                "370304": "红寺堡开发区",
                "370305": "青铜峡市",
                "370401": "原州区",
                "370402": "西吉县",
                "370403": "隆德县",
                "370404": "泾源县",
                "370405": "彭阳县",
                "370501": "沙坡头区",
                "370502": "中宁县",
                "370503": "海原县",
                "380101": "门源县",
                "380102": "祁连县",
                "380103": "海晏县",
                "380104": "刚察县",
                "380201": "城东区",
                "380202": "城中区",
                "380203": "城西区",
                "380204": "城北区",
                "380205": "大通县",
                "380206": "湟中县",
                "380207": "湟源县",
                "380301": "平安县",
                "380302": "民和县",
                "380303": "乐都县",
                "380304": "互助县",
                "380305": "化隆县",
                "380306": "循化县",
                "380401": "同仁县",
                "380402": "尖扎县",
                "380403": "泽库县",
                "380404": "河南县",
                "380501": "共和县",
                "380502": "同德县",
                "380503": "贵德县",
                "380504": "贵南县",
                "380505": "兴海县",
                "380601": "玛沁县",
                "380602": "班玛县",
                "380603": "达日县",
                "380604": "久治县",
                "380605": "甘德县",
                "380606": "玛多县",
                "380701": "玉树市",
                "380702": "称多县",
                "380703": "治多县",
                "380704": "曲麻莱县",
                "380705": "杂多县",
                "380706": "囊谦县",
                "380801": "格尔木市",
                "380802": "德令哈市",
                "380803": "乌兰县",
                "380804": "都兰县",
                "380805": "天峻县",
                "390101": "塔城市",
                "390102": "乌苏市",
                "390103": "额敏县",
                "390104": "沙湾县",
                "390105": "托里县",
                "390106": "裕民县",
                "390107": "和布克赛尔县",
                "390201": "哈密市",
                "390202": "巴里坤县",
                "390203": "伊吾县",
                "390301": "和田市",
                "390302": "和田县",
                "390303": "墨玉县",
                "390304": "皮山县",
                "390305": "洛浦县",
                "390306": "策勒县",
                "390307": "于田县",
                "390308": "民丰县",
                "390401": "阿勒泰市",
                "390402": "布尔津县",
                "390403": "富蕴县",
                "390404": "福海县",
                "390405": "哈巴河县",
                "390406": "青河县",
                "390407": "吉木乃县",
                "390501": "阿图什市",
                "390502": "阿克陶县",
                "390503": "阿合奇县",
                "390504": "乌恰县",
                "390601": "博乐市",
                "390602": "精河县",
                "390603": "温泉县",
                "390701": "图木舒克市",
                "390801": "五家渠市",
                "390901": "独山子区",
                "390902": "克拉玛依区",
                "390903": "白碱滩区",
                "390904": "乌尔禾区",
                "391001": "天山区",
                "391002": "沙依巴克区",
                "391003": "新市区",
                "391004": "水磨沟区",
                "391005": "头屯河区",
                "391006": "达坂城区",
                "391007": "米东区",
                "391008": "乌鲁木齐县",
                "391101": "伊宁市",
                "391102": "奎屯市",
                "391103": "伊宁县",
                "391104": "察布查尔县",
                "391105": "霍城县",
                "391106": "巩留县",
                "391107": "新源县",
                "391108": "昭苏县",
                "391109": "特克斯县",
                "391110": "尼勒克县",
                "391201": "石河子市",
                "391301": "昌吉市",
                "391302": "阜康市",
                "391303": "呼图壁县",
                "391304": "玛纳斯县",
                "391305": "奇台县",
                "391306": "吉木萨尔县",
                "391307": "木垒县",
                "391308": "五家渠市",
                "391401": "吐鲁番市",
                "391402": "鄯善县",
                "391403": "托克逊县",
                "391501": "库尔勒市",
                "391502": "轮台县",
                "391503": "尉犁县",
                "391504": "若羌县",
                "391505": "且末县",
                "391506": "焉耆县",
                "391507": "和静县",
                "391508": "和硕县",
                "391509": "博湖县",
                "391601": "阿克苏市",
                "391602": "温宿县",
                "391603": "库车县",
                "391604": "沙雅县",
                "391605": "新和县",
                "391606": "拜城县",
                "391607": "乌什县",
                "391608": "阿瓦提县",
                "391609": "柯坪县",
                "391701": "喀什市",
                "391702": "疏附县",
                "391703": "疏勒县",
                "391704": "英吉沙县",
                "391705": "泽普县",
                "391706": "莎车县",
                "391707": "叶城县",
                "391708": "麦盖提县",
                "391709": "岳普湖县",
                "391710": "伽师县",
                "391711": "巴楚县",
                "391712": "塔什库尔干县",
                "391713": "图木舒克市",
                "391801": "阿拉尔市",
                "400101": "城关区",
                "400102": "堆龙德庆县",
                "400103": "当雄县",
                "400104": "尼木县",
                "400105": "曲水县",
                "400106": "林周县",
                "400107": "达孜县",
                "400108": "墨竹工卡县",
                "400201": "卡若区",
                "400202": "江达县",
                "400203": "贡觉县",
                "400204": "类乌齐县",
                "400205": "丁青县",
                "400206": "察雅县",
                "400207": "八宿县",
                "400208": "左贡县",
                "400209": "芒康县",
                "400210": "洛隆县",
                "400211": "边坝县",
                "400301": "乃东县",
                "400302": "扎囊县",
                "400303": "贡嘎县",
                "400304": "桑日县",
                "400305": "琼结县",
                "400306": "曲松县",
                "400307": "措美县",
                "400308": "洛扎县",
                "400309": "加查县",
                "400310": "隆子县",
                "400311": "错那县",
                "400312": "浪卡子县",
                "400401": "桑珠孜区",
                "400402": "南木林县",
                "400403": "江孜县",
                "400404": "定日县",
                "400405": "萨迦县",
                "400406": "拉孜县",
                "400407": "昂仁县",
                "400408": "谢通门县",
                "400409": "白朗县",
                "400410": "仁布县",
                "400411": "康马县",
                "400412": "定结县",
                "400413": "仲巴县",
                "400414": "亚东县",
                "400415": "吉隆县",
                "400416": "聂拉木县",
                "400417": "萨嘎县",
                "400418": "岗巴县",
                "400501": "那曲县",
                "400502": "嘉黎县",
                "400503": "比如县",
                "400504": "聂荣县",
                "400505": "安多县",
                "400506": "申扎县",
                "400507": "索县",
                "400508": "班戈县",
                "400509": "巴青县",
                "400510": "尼玛县",
                "400601": "普兰县",
                "400602": "札达县",
                "400603": "日土县",
                "400604": "革吉县",
                "400605": "革吉县",
                "400606": "改则县",
                "400607": "措勤县",
                "400701": "林芝县",
                "400702": "工布江达县",
                "400703": "米林县",
                "400704": "墨脱县",
                "400705": "波密县",
                "400706": "察隅县",
                "400707": "朗县",
                "410101": "万州区",
                "410102": "涪陵区",
                "410103": "渝中区",
                "410104": "大渡口区",
                "410105": "江北区",
                "410106": "沙坪坝区",
                "410107": "九龙坡区",
                "410108": "南岸区",
                "410109": "北碚区",
                "410110": "万盛区",
                "410111": "双桥区",
                "410112": "渝北区",
                "410113": "巴南区",
                "410114": "黔江区",
                "410115": "长寿区",
                "410116": "江津区",
                "410117": "合川区",
                "410118": "永川区",
                "410119": "南川区",
                "410120": "綦江区",
                "410121": "潼南县",
                "410122": "铜梁区",
                "410123": "大足区",
                "410124": "荣昌县",
                "410125": "璧山区",
                "410126": "梁平县",
                "410127": "城口县",
                "410128": "丰都县",
                "410129": "垫江县",
                "410130": "武隆县",
                "410131": "忠县",
                "410132": "开县",
                "410133": "云阳县",
                "410134": "奉节县",
                "410135": "巫山县",
                "410136": "巫溪县",
                "410137": "石柱县",
                "410138": "秀山县",
                "410139": "酉阳县",
                "410140": "彭水县"
            },
            "province_list": {
                "110000": "北京",
                "120000": "上海",
                "130000": "天津",
                "140000": "内蒙古",
                "150000": "山西省",
                "160000": "河北省",
                "170000": "辽宁省",
                "180000": "吉林省",
                "190000": "黑龙江",
                "200000": "江苏省",
                "210000": "安徽省",
                "220000": "山东省",
                "230000": "浙江省",
                "240000": "江西省",
                "250000": "福建省",
                "260000": "湖南省",
                "270000": "湖北省",
                "280000": "河南省",
                "290000": "广东省",
                "300000": "海南省",
                "310000": "广西省",
                "320000": "贵州省",
                "330000": "四川省",
                "340000": "云南省",
                "350000": "陕西省",
                "360000": "甘肃省",
                "370000": "宁夏",
                "380000": "青海省",
                "390000": "新疆",
                "400000": "西藏自治区",
                "410000": "重庆"
            },
            "city_list": {
                "110100": "北京市",
                "120100": "上海市",
                "130100": "天津市",
                "140100": "呼伦贝尔市",
                "140200": "呼和浩特市",
                "140300": "包头市",
                "140400": "乌海市",
                "140500": "乌兰察布市",
                "140600": "通辽市",
                "140700": "赤峰市",
                "140800": "鄂尔多斯市",
                "140900": "巴彦淖尔市",
                "141000": "锡林郭勒盟",
                "141100": "兴安盟",
                "141200": "阿拉善盟",
                "150100": "朔州市",
                "150200": "忻州市",
                "150300": "太原市",
                "150400": "大同市",
                "150500": "阳泉市",
                "150600": "晋中市",
                "150700": "长治市",
                "150800": "晋城市",
                "150900": "临汾市",
                "151000": "吕梁市",
                "151100": "运城市",
                "160100": "邯郸市",
                "160200": "石家庄市",
                "160300": "保定市",
                "160400": "张家口市",
                "160500": "承德市",
                "160600": "唐山市",
                "160700": "廊坊市",
                "160800": "沧州市",
                "160900": "衡水市",
                "161000": "邢台市",
                "161100": "秦皇岛市",
                "170100": "沈阳市",
                "170200": "铁岭市",
                "170300": "大连市",
                "170400": "鞍山市",
                "170500": "抚顺市",
                "170600": "本溪市",
                "170700": "丹东市",
                "170800": "锦州市",
                "170900": "营口市",
                "171000": "阜新市",
                "171100": "辽阳市",
                "171200": "朝阳市",
                "171300": "盘锦市",
                "171400": "葫芦岛市",
                "180100": "长春市",
                "180200": "吉林市",
                "180300": "延边州",
                "180400": "四平市",
                "180500": "通化市",
                "180600": "白城市",
                "180700": "辽源市",
                "180800": "松原市",
                "180900": "白山市",
                "190100": "哈尔滨市",
                "190200": "齐齐哈尔市",
                "190300": "牡丹江市",
                "190400": "佳木斯市",
                "190500": "绥化市",
                "190600": "黑河市",
                "190700": "大兴安岭地区",
                "190800": "伊春市",
                "190900": "大庆市",
                "191000": "七台河市",
                "191100": "鸡西市",
                "191200": "鹤岗市",
                "191300": "双鸭山市",
                "200100": "南京市",
                "200200": "无锡市",
                "200300": "镇江市",
                "200400": "苏州市",
                "200500": "南通市",
                "200600": "扬州市",
                "200700": "盐城市",
                "200800": "徐州市",
                "200900": "淮安市",
                "201000": "连云港市",
                "201100": "常州市",
                "201200": "泰州市",
                "201300": "宿迁市",
                "210100": "滁州市",
                "210200": "合肥市",
                "210300": "蚌埠市",
                "210400": "芜湖市",
                "210500": "淮南市",
                "210600": "马鞍山市",
                "210700": "安庆市",
                "210800": "宿州市",
                "210900": "阜阳市",
                "211000": "黄山市",
                "211100": "亳州市",
                "211200": "淮北市",
                "211300": "铜陵市",
                "211400": "宣城市",
                "211500": "六安市",
                "211600": "池州市",
                "220100": "菏泽市",
                "220200": "济南市",
                "220300": "青岛市",
                "220400": "淄博市",
                "220500": "德州市",
                "220600": "烟台市",
                "220700": "潍坊市",
                "220800": "济宁市",
                "220900": "泰安市",
                "221000": "临沂市",
                "221100": "滨州市",
                "221200": "东营市",
                "221300": "威海市",
                "221400": "枣庄市",
                "221500": "日照市",
                "221600": "莱芜市",
                "221700": "聊城市",
                "230100": "衢州市",
                "230200": "杭州市",
                "230300": "湖州市",
                "230400": "嘉兴市",
                "230500": "宁波市",
                "230600": "绍兴市",
                "230700": "台州市",
                "230800": "温州市",
                "230900": "丽水市",
                "231000": "金华市",
                "231100": "舟山市",
                "240100": "鹰潭市",
                "240200": "新余市",
                "240300": "南昌市",
                "240400": "九江市",
                "240500": "上饶市",
                "240600": "抚州市",
                "240700": "宜春市",
                "240800": "吉安市",
                "240900": "赣州市",
                "241000": "景德镇市",
                "241100": "萍乡市",
                "250100": "福州市",
                "250200": "厦门市",
                "250300": "宁德市",
                "250400": "莆田市",
                "250500": "泉州市",
                "250600": "漳州市",
                "250700": "龙岩市",
                "250800": "三明市",
                "250900": "南平市",
                "260100": "岳阳市",
                "260200": "长沙市",
                "260300": "湘潭市",
                "260400": "株洲市",
                "260500": "衡阳市",
                "260600": "郴州市",
                "260700": "常德市",
                "260800": "益阳市",
                "260900": "娄底市",
                "261000": "邵阳市",
                "261100": "湘西州",
                "261200": "张家界市",
                "261300": "怀化市",
                "261400": "永州市",
                "270100": "武汉市",
                "270200": "襄阳市",
                "270300": "鄂州市",
                "270400": "孝感市",
                "270500": "黄冈市",
                "270600": "黄石市",
                "270700": "咸宁市",
                "270800": "荆州市",
                "270900": "宜昌市",
                "271000": "恩施州",
                "271100": "十堰市",
                "271200": "神农架",
                "271300": "随州市",
                "271400": "荆门市",
                "271500": "湖北省直辖",
                "280100": "商丘市",
                "280200": "郑州市",
                "280300": "安阳市",
                "280400": "新乡市",
                "280500": "许昌市",
                "280600": "平顶山市",
                "280700": "信阳市",
                "280800": "南阳市",
                "280900": "开封市",
                "281000": "洛阳市",
                "281100": "焦作市",
                "281200": "鹤壁市",
                "281300": "濮阳市",
                "281400": "周口市",
                "281500": "漯河市",
                "281600": "驻马店市",
                "281700": "三门峡市",
                "281800": "济源市",
                "290100": "广州市",
                "290200": "汕尾市",
                "290300": "阳江市",
                "290400": "揭阳市",
                "290500": "茂名市",
                "290600": "江门市",
                "290700": "韶关市",
                "290800": "惠州市",
                "290900": "梅州市",
                "291000": "汕头市",
                "291100": "深圳市",
                "291200": "珠海市",
                "291300": "佛山市",
                "291400": "肇庆市",
                "291500": "湛江市",
                "291600": "中山市",
                "291700": "河源市",
                "291800": "清远市",
                "291900": "云浮市",
                "292000": "潮州市",
                "292100": "东莞市",
                "300100": "三沙市",
                "300200": "东方市",
                "300300": "儋州市",
                "300400": "五指山市",
                "300500": "万宁市",
                "300600": "文昌市",
                "300700": "琼海市",
                "300800": "三亚市",
                "300900": "海口市",
                "301000": "海南省直辖",
                "310100": "防城港市",
                "310200": "南宁市",
                "310300": "柳州市",
                "310400": "桂林市",
                "310500": "梧州市",
                "310600": "贵港市",
                "310700": "百色市",
                "310800": "钦州市",
                "310900": "河池市",
                "311000": "北海市",
                "311100": "崇左市",
                "311200": "来宾市",
                "311300": "贺州市",
                "311400": "玉林市",
                "320100": "贵阳市",
                "320200": "遵义市",
                "320300": "安顺市",
                "320400": "黔南州",
                "320500": "黔东南州",
                "320600": "铜仁市",
                "320700": "毕节市",
                "320800": "六盘水市",
                "320900": "黔西南州",
                "330100": "成都市",
                "330200": "攀枝花市",
                "330300": "自贡市",
                "330400": "绵阳市",
                "330500": "南充市",
                "330600": "达州市",
                "330700": "遂宁市",
                "330800": "广安市",
                "330900": "巴中市",
                "331000": "泸州市",
                "331100": "宜宾市",
                "331200": "内江市",
                "331300": "眉山市",
                "331400": "凉山州",
                "331500": "雅安市",
                "331600": "甘孜州",
                "331700": "阿坝州",
                "331800": "德阳市",
                "331900": "广元市",
                "332000": "资阳市",
                "332100": "乐山市",
                "340100": "西双版纳州",
                "340200": "德宏州",
                "340300": "昭通市",
                "340400": "昆明市",
                "340500": "大理州",
                "340600": "红河州",
                "340700": "曲靖市",
                "340800": "保山市",
                "340900": "文山州",
                "341000": "玉溪市",
                "341100": "楚雄州",
                "341200": "普洱市",
                "341300": "临沧市",
                "341400": "怒江州",
                "341500": "迪庆州",
                "341600": "丽江市",
                "350100": "西安市",
                "350200": "咸阳市",
                "350300": "延安市",
                "350400": "榆林市",
                "350500": "渭南市",
                "350600": "商洛市",
                "350700": "安康市",
                "350800": "汉中市",
                "350900": "宝鸡市",
                "351000": "铜川市",
                "360100": "临夏州",
                "360200": "兰州市",
                "360300": "定西市",
                "360400": "平凉市",
                "360500": "庆阳市",
                "360600": "武威市",
                "360700": "张掖市",
                "360800": "酒泉市",
                "360900": "天水市",
                "361000": "陇南市",
                "361100": "金昌市",
                "361200": "甘南州",
                "361300": "嘉峪关市",
                "361400": "白银市",
                "370100": "银川市",
                "370200": "石嘴山市",
                "370300": "吴忠市",
                "370400": "固原市",
                "370500": "中卫市",
                "380100": "海北州",
                "380200": "西宁市",
                "380300": "海东地区",
                "380400": "黄南州",
                "380500": "海南州",
                "380600": "果洛州",
                "380700": "玉树州",
                "380800": "海西州",
                "390100": "塔城地区",
                "390200": "哈密地区",
                "390300": "和田地区",
                "390400": "阿勒泰地区",
                "390500": "克孜勒苏州",
                "390600": "博尔塔拉州",
                "390700": "图木舒克市",
                "390800": "五家渠市",
                "390900": "克拉玛依市",
                "391000": "乌鲁木齐市",
                "391100": "伊犁州",
                "391200": "石河子市",
                "391300": "昌吉州",
                "391400": "吐鲁番地区",
                "391500": "巴音郭楞州",
                "391600": "阿克苏地区",
                "391700": "喀什地区",
                "391800": "阿拉尔市",
                "400100": "拉萨市",
                "400200": "昌都地区",
                "400300": "山南地区",
                "400400": "日喀则地区",
                "400500": "那曲地区",
                "400600": "阿里地区",
                "400700": "林芝地区",
                "410100": "重庆市"
            }
        }
    };
    return areaResult['data'];
};
