import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// 首先声明一个需要全局维护的状态 state,比如 我这里举例的name
const state = {
  name: '测试名称' // 默认值
  // id: xxx  如果还有全局状态也可以在这里添加
};

export default new Vuex.Store({
  state: state, // 共同维护的一个状态，state里面可以是很多个全局状态
  getters: {},
  mutations: {},
  actions: {}, // 数据的异步操作
  modules: {} // 处理数据的唯一途径，state的改变或赋值只能在这里
});
